import {
  accuralTypeDropDown,
  condoEligibilityDropDown,
  loanPurposeDropDown,
  loanStructureDropDown,
  prepaymentPenaltyTypeDropDown,
  rateType,
  thirtyYearLoanPurpose,
  yOrNDropDown,
  thirtyYearPropertyTypeDropDown,
  foreignNationalOptions,
  yesNoDropdown,
  // toorakProductDropDown,
  // thirtyYearToorakProduct,
  propertyTypeDropDown,
  USStates
} from "../constants/loanCreationDropDownValues";

export const BridgeLoanFields = [
  // {
  //   label: "Primary Loan ID",
  //   path: "loanInfo.primaryLoanId",
  //   field: "primaryLoanId",
  //   aliasKey: "primaryLoanID",
  //   rulesKey: "primaryLoanId",
  //   type: "alphanumeric"
  // },
  {
    label: "Gross Margin (%)",
    path: "loanEconomics.grossArmMargin",
    field: "grossArmMargin",
    type: "percentage",
    skipRules: true,
    hidden: (loanDetails: any) => {
      return loanDetails?.loanEconomics?.rateType !== "Adjustable"
    }
  },
  {
    label: "Recourse",
    path: "loanInfo.recourseString",
    auditPath: "loanInfo.recourseString",
    field: "recourseString",
    aliasKey: "selectedRecourse",
    rulesKey: "recourse",
    type: "dropdown",
    values: ["Full Recourse", "Non-Recourse"]
  },
  {
    label: "Number Of Properties",
    path: "loanInfo.noOfProperties",
    field: "noOfProperties",
    aliasKey: "numberOfProperties",
    rulesKey: "noOfProperties",
    type: "number"
  },
  // {
  //   label: "Cut-off Date",
  //   path: "loanInfo.cutOffDate",
  //   field: "cutOffDate",
  //   type: "dateOnly"
  // },
  // {
  //   label: "Toorak Product",
  //   path: "loanInfo.toorakProduct",
  //   field: "toorakProduct",
  //   aliasKey: "selectedToorakProduct",
  //   rulesKey: "toorakProduct",
  //   type: "dropdown",
  //   values: toorakProductDropDown
  // },
  {
    label: "Cut-off Date Loan Amount ($)",
    path: "loanEconomics.cutOffDateLoanAmount",
    field: "cutOffDateLoanAmount",
    aliasKey: "cutOffLoanAmount",
    rulesKey: "cutOffDateLoanAmount",
    disableAfterApproved: true,
    type: "currency"
  },
  {
    label: "Cut-off Date Maximum Amount ($)",
    path: "loanEconomics.cutOffDateMaximumLoanAmount",
    field: "cutOffDateMaximumLoanAmount",
    aliasKey: "cutOffMaxLoanAmount",
    rulesKey: "cutOffDateMaximumLoanAmount",
    disableAfterApproved: true,
    type: "currency"
  },
  // {
  //   label: "Insurance + Amortization ($)",
  //   path: "loanEconomics.insuranceAndAmortization",
  //   field: "insuranceAndAmortization",
  //   type: "currency"
  // },
  // {
  //   label: "Insurance ($)",
  //   path: "loanDetailId.loanSummaryId.insurance",
  //   field: "insurance",
  //   type: "currency",
  //   skipRules: true
  // },
  {
    label: "Loan Structure",
    path: "loanInfo.loanStructure",
    field: "loanStructure",
    aliasKey: "selectedLoanStructure",
    rulesKey: "loanStructure",
    type: "dropdown",
    disableAfterApproved: true,
    values: loanStructureDropDown
  },
  {
    label: "Loan Purpose",
    path: "loanInfo.loanPurpose",
    field: "loanPurpose",
    type: "dropdown",
    disableAfterApproved: true,
    values: loanPurposeDropDown
  },
  {
    label: "Financed Budget Amount ($)",
    path: "loanEconomics.financedBudgetAmount",
    field: "financedBudgetAmount",
    disableAfterApproved: true,
    type: "currency"
  },
  {
    label: "Total Budget Amount ($)",
    path: "loanEconomics.totalBudgetAmount",
    field: "totalBudgetAmount",
    aliasKey: "budgetAmount",
    rulesKey: "totalBudgetAmount",
    disableAfterApproved: true,
    type: "currency"
  },
  {
    label: "Financed Interest Reserve ($)",
    path: "loanEconomics.financedInterestReserve",
    field: "financedInterestReserve",
    aliasKey: "financialInterestReserve",
    rulesKey: "financedInterestReserve",
    type: "currency",
    disableAfterApproved: true
  },
  {
    label: "Original Loan Amount ($)",
    path: "loanEconomics.originalLoanAmount",
    field: "originalLoanAmount",
    aliasKey: "loanAmount",
    rulesKey: "originalLoanAmount",
    disableAfterApproved: true,
    type: "currency"
  },
  {
    label: "Original Maximum Loan Amount ($)",
    path: "loanEconomics.originalMaximumLoanAmount",
    field: "originalMaximumLoanAmount",
    aliasKey: "maxLoanAmount",
    rulesKey: "originalMaximumLoanAmount",
    disableAfterApproved: true,
    type: "currency"
  },
  {
    label: "Interest Rate (As of Cut off Date) (%)",
    path: "loanEconomics.interestRateAsOfCutOffDate",
    field: "interestRateAsOfCutOffDate",
    aliasKey: "interestRate",
    disableAfterApproved: true,
    type: "percentage"
  },
  {
    label: "Accrual Type",
    path: "loanEconomics.accrualType",
    field: "accrualType",
    type: "dropdown",
    values: accuralTypeDropDown
  },
  {
    label: "Origination Date",
    path: "loanInfo.originationDate",
    field: "originationDate",
    disableAfterApproved: true,
    type: "date"
  },
  {
    label: "First Payment Date",
    path: "loanInfo.firstPaymentDateOfLoan",
    field: "firstPaymentDateOfLoan",
    aliasKey: "firstPaymentDate",
    rulesKey: "firstPaymentDateOfLoan",
    disableAfterApproved: true,
    type: "date"
  },
  {
    label: "Original Maturity Date",
    path: "loanInfo.originalMaturityDate",
    field: "originalMaturityDate",
    aliasKey: "maturityDate",
    rulesKey: "originalMaturityDate",
    disableAfterApproved: true,
    type: "date"
  },
  {
    label: "Total Origination and Discount Points (%)",
    path: "loanEconomics.totalOriginationAndDiscountPoints",
    field: "totalOriginationAndDiscountPoints",
    aliasKey: "discountPoints",
    rulesKey: "totalOriginationAndDiscountPoints",
    type: "percentage"
  },
  {
    label: "Cash Out Amount ($)",
    path: "loanEconomics.cashOutAmount",
    field: "cashOutAmount",
    type: "currency"
  },
  {
    label: "Extension Option (Months)",
    path: "loanInfo.extensionOptions",
    field: "extensionOptions",
    aliasKey: "extensionOption",
    rulesKey: "extensionOptions",
    type: "number"
  },
  {
    label: "Default Rate (%)",
    path: "loanInfo.defaultRate",
    field: "defaultRate",
    type: "percentage",
    skipRules: true
  },
  {
    label: "Default Interest Rate Type",
    path: "loanEconomics.defaultInterestRateType",
    field: "defaultInterestRateType",
    type: "dropdown",
    values: ["Fixed", "Increasing"],
    skipRules: true
  },
  {
    label: "Default Rate Curable (Grace Days)",
    path: "loanInfo.defaultRateCurable",
    field: "defaultRateCurable",
    type: "number",
    skipRules: true
  },
  {
    label: "Life Rate Cap (%)",
    path: "loanEconomics.lifeRateCap",
    field: "lifeRateCap",
    type: "percentage",
    skipRules: true
  },
  {
    label: "Subsequent Periodic Cap (%)",
    path: "loanEconomics.subsequentPeriodicCap",
    field: "subsequentPeriodicCap",
    type: "percentage",
    skipRules: true
  },
  {
    label: "Initial Periodic Cap (%)",
    path: "loanEconomics.initialPeriodicCap",
    field: "initialPeriodicCap",
    type: "percentage",
    skipRules: true
  },
  {
    label: "Insurance Agent First Name",
    path: "loanDetailId.loanSummaryId.insuranceAgentFirstName",
    field: "insuranceAgentFirstName",
    type: "text",
    skipRules: true
  },
  {
    label: "Insurance Agent Last Name",
    path: "loanDetailId.loanSummaryId.insuranceAgentLastName",
    field: "insuranceAgentLastName",
    type: "text",
    skipRules: true
  },
  {
    label: "Insurance Agent Email",
    path: "loanDetailId.loanSummaryId.insuranceAgentEmail",
    field: "insuranceAgentEmail",
    type: "email",
    skipRules: true
  },
  {
    label: "Insurance Agent Contact Number",
    path: "loanDetailId.loanSummaryId.insuranceAgentContactNumber",
    field: "insuranceAgentContactNumber",
    type: "phone",
    skipRules: true
  },
  {
    label: "Insurance Expiration Date",
    path: "loanDetailId.loanSummaryId.insuranceExpirationDate",
    field: "insuranceExpirationDate",
    type: "dateOnly",
    skipRules: true
  },
  {
    label: "Insurance Company",
    path: "loanDetailId.loanSummaryId.insuranceCompany",
    field: "insuranceCompany",
    type: "text",
    skipRules: true
  },
  {
    label: "Insurance Type",
    path: "loanDetailId.loanSummaryId.insuranceType",
    field: "insuranceType",
    type: "text",
    skipRules: true
  },
  {
    label: "Insurance Company Address",
    path: "loanDetailId.loanSummaryId.insuranceCompanyAddress",
    field: "insuranceCompanyAddress",
    type: "billingaddress",
    skipRules: true
  },
  {
    label: "Policy Number",
    path: "loanDetailId.loanSummaryId.policyNumber",
    field: "policyNumber",
    type: "text",
    skipRules: true
  },
  {
    label: "Frequency",
    path: "loanDetailId.loanSummaryId.frequency",
    field: "frequency",
    type: "dropdown",
    values:["Monthly","Quarterly","Half-yearly","Yearly"],
    skipRules: true
  },
  {
    label: "Responsibility",
    path: "loanDetailId.loanSummaryId.responsibility",
    field: "responsibility",
    type: "dropdown",
    values:["Escrowed","Borrower"],
    skipRules: true
  },
  // {
  //   label: "Annual Property Taxes for All ($)",
  //   path: "loanDetailId.loanSummaryId.taxes",
  //   field: "taxes",
  //   type: "currency",
  //   skipRules: true
  // },
  {
    label: "Initial Rate Adjustment Period",
    path: "loanEconomics.initialRateAdjustmentPeriod",
    field: "initialRateAdjustmentPeriod",
    type: "number",
    skipRules: true
  },
  {
    label: "Initial Pay Adjustment Period",
    path: "loanEconomics.initialPayAdjustmentPeriod",
    field: "initialPayAdjustmentPeriod",
    type: "number",
    skipRules: true
  },
  {
    label: "Interest Only",
    path: "loanEconomics.interestOnly",
    field: "interestOnly",
    type: "dropdown",
    values: yOrNDropDown,
    skipRules: true
  },
  {
    label: "Cross Collaterlization (Y/N)",
    path: "loanInfo.crossCollaterlization",
    field: "crossCollaterlization",
    aliasKey: "selectedCross",
    rulesKey: "crossCollaterlization",
    type: "dropdown",
    values: yOrNDropDown
  },
  {
    label: "Late Charge ($)",
    path: "loanEconomics.lateCharge",
    field: "lateCharge",
    type: "currency",
    skipRules: true
  },
  {
    label: "Late Charge (%)",
    path: "loanEconomics.lateChargePercentage",
    field: "lateChargePercentage",
    type: "percentage",
    skipRules: true
  },
  {
    label: "Interest Payment ($)",
    path: "loanEconomics.originalMonthlyPiPayment",
    field: "originalMonthlyPiPayment",
    type: "currency",
    skipRules: true
  },
  {
    label: "Gross Amount Due from Borrower ($)",
    path: "loanEconomics.grossAmountDueFromBorrowerHUD",
    field: "grossAmountDueFromBorrowerHUD",
    type: "currency",
    skipRules: true
  },
  {
    label: "Origination Fee ($)",
    path: "loanFees.originationFeeHUD",
    field: "originationFeeHUD",
    type: "currency",
    skipRules: true
  },
  {
    label: "Broker Fee ($)",
    path: "loanFees.brokerFeeHUD",
    field: "brokerFeeHUD",
    type: "currency",
    skipRules: true
  },
  {
    label: "Assignment Fee ($)",
    path: "loanFees.assignmentFeeHUD",
    field: "assignmentFeeHUD",
    type: "currency",
    skipRules: true
  },
  {
    label: "Per Diem Interest ($)",
    path: "loanFees.perDiemInterestHUD",
    field: "perDiemInterestHUD",
    type: "currency",
    skipRules: true
  },
  {
    label: "Flood Insurance Coverage ($)",
    path: "loanEconomics.floodInsuranceCoverage",
    field: "floodInsuranceCoverage",
    type: "currency",
    skipRules: true
  },
  {
    label: "HOI Dwelling Coverage ($)",
    path: "loanEconomics.hoiDwellingCoverage",
    field: "hoiDwellingCoverage",
    type: "currency",
    skipRules: true
  },
  {
    label: "ACH Activation Type",
    path: "achData.achActivationType",
    field: "achActivationType",
    type: "text",
    skipRules: true
  },
  {
    label: "Bank ABA Routing Number",
    path: "achData.bankABARoutingNumber",
    field: "bankABARoutingNumber",
    type: "abaroutingnumber",
    skipRules: true
  },
  {
    label: "Bank Name",
    path: "achData.bankName",
    field: "bankName",
    type: "text",
    skipRules: true
  },
  {
    label: "Bank City",
    path: "achData.bankCity",
    field: "bankCity",
    type: "text",
    skipRules: true
  },
  {
    label: "Bank State",
    path: "achData.bankState",
    field: "bankState",
    type: "dropdown",
    values: USStates,
    skipRules: true
  },
  {
    label: "Bank Account Number",
    path: "achData.bankAccountNumber",
    field: "bankAccountNumber",
    type: "Number",
    skipRules: true
  },
  {
    label: "Bank Account Type",
    path: "achData.bankAccountType",
    field: "bankAccountType",
    type: "dropdown",
    values: ["Savings Account", "Checking Account"],
    skipRules: true
  },
  {
    label: "PreNote",
    path: "achData.preNote",
    field: "preNote",
    type: "text",
    skipRules: true
  },
  {
    label: "Name on Account Indicator",
    path: "achData.nameOnAccountIndicator",
    field: "nameOnAccountIndicator",
    type: "dropdown",
    values: ["B", "C", "3"],
    skipRules: true
  },
  {
    label: "Drafting Option",
    path: "achData.draftingOption",
    field: "draftingOption",
    type: "dropdown",
    values: ["Monthly", "Bi-Weekly", "Weekly", "Semi-Monthly"],
    skipRules: true
  },
  {
    label: "Draft Day 1",
    path: "achData.draftDay1",
    field: "draftDay1",
    type: "dateOnly",
    skipRules: true
  },
  {
    label: "Draft Day 2",
    path: "achData.draftDay2",
    field: "draftDay2",
    type: "dateOnly",
    skipRules: true
  },
  {
    label: "Desired Start Date",
    path: "achData.desiredStartDate",
    field: "desiredStartDate",
    type: "dateOnly",
    skipRules: true
  },
  {
    label: "Additional Principal",
    path: "achData.additionalPrincipal",
    field: "additionalPrincipal",
    type: "currency",
    skipRules: true
  },
  {
    label: "Additional Escrow",
    path: "achData.additionalEscrow",
    field: "additionalEscrow",
    type: "currency",
    skipRules: true
  },
  {
    label: "Additional Other",
    path: "achData.additionalOther",
    field: "additionalOther",
    type: "currency",
    skipRules: true
  },
  {
    label: "Draft Fee",
    path: "achData.draftFee",
    field: "draftFee",
    type: "currency",
    skipRules: true
  },
  {
    label: "Fixed Draft Amount",
    path: "achData.fixedDraftAmount",
    field: "fixedDraftAmount",
    type: "currency",
    skipRules: true
  },
  {
    label: "Next Transfer Date",
    path: "achData.nextTransferDate",
    field: "nextTransferDate",
    type: "dateOnly",
    skipRules: true
  },
  {
    label: "Last Draft Date",
    path: "achData.lastDraftDate",
    field: "lastDraftDate",
    type: "dateOnly",
    skipRules: true
  }
];

export const DSCRLoanFields = [
  {
    label: "Recourse",
    path: "loanInfo.recourseString",
    auditPath: "loanInfo.recourse",
    field: "recourseString",
    aliasKey: "selectedRecourse",
    rulesKey: "recourse",
    type: "dropdown",
    values: ["Full Recourse", "Non-Recourse"]
  },
  // {
  //   label: "Cut Off Date",
  //   path: "loanInfo.cutOffDate",
  //   field: "cutOffDate",
  //   type: "dateOnly"
  // },
  // {
  //   label: "Toorak Product",
  //   path: "loanInfo.toorakProduct",
  //   field: "toorakProduct",
  //   aliasKey: "selectedToorakProduct",
  //   rulesKey: "toorakProduct",
  //   type: "dropdown",
  //   values: thirtyYearToorakProduct
  // },
  {
    label: "Number Of Properties",
    path: "loanInfo.noOfProperties",
    field: "noOfProperties",
    aliasKey: "numberOfProperties",
    rulesKey: "noOfProperties",
    type: "number"
  },
  {
    label: "Cross Collaterlization (Y/N)",
    path: "loanInfo.crossCollaterlization",
    field: "crossCollaterlization",
    aliasKey: "selectedCross",
    rulesKey: "crossCollaterlization",
    type: "dropdown",
    values: yOrNDropDown
  },
  {
    label: "Loan Purpose",
    path: "loanInfo.loanPurpose",
    field: "loanPurpose",
    type: "dropdown",
    disableAfterApproved: true,
    values: thirtyYearLoanPurpose
  },
  {
    label: "Condo Eligibility",
    path: "loanInfo.condoEligibility",
    field: "condoEligibility",
    type: "dropdown",
    values: condoEligibilityDropDown
  },
  {
    label: "Financed Interest Reserve ($)",
    path: "loanEconomics.financedInterestReserve",
    field: "financedInterestReserve",
    aliasKey: "financialInterestReserve",
    rulesKey: "financedInterestReserve",
    type: "currency",
    disableAfterApproved: true
  },
  {
    label: "Original Loan Amount ($)",
    path: "loanEconomics.originalLoanAmount",
    field: "originalLoanAmount",
    aliasKey: "loanAmount",
    rulesKey: "originalLoanAmount",
    disableAfterApproved: true,
    type: "currency"
  },
  {
    label: "Current Loan Balance ($)",
    path: "loanEconomics.currentLoanBalance",
    field: "currentLoanBalance",
    type: "currency",
    disableAfterApproved: true,
  },
  {
    label: "Interest Only Period",
    path: "loanEconomics.interestOnlyPeriod",
    field: "interestOnlyPeriod",
    type: "number"
  },
  {
    label: "Prepay Penalties Type",
    path: "loanEconomics.prepayPenaltyType",
    field: "prepayPenaltyType",
    type: "dropdown",
    values: prepaymentPenaltyTypeDropDown
  },
  {
    label: "Rate Type",
    path: "loanEconomics.rateType",
    field: "rateType",
    type: "dropdown",
    values: rateType
  },
  {
    label: "Interest Rate (As of Cut off Date) (%)",
    path: "loanEconomics.interestRateAsOfCutOffDate",
    field: "interestRateAsOfCutOffDate",
    aliasKey: "interestRate",
    disableAfterApproved: true,
    type: "percentage"
  },
  {
    label: "Origination Date",
    path: "loanInfo.originationDate",
    field: "originationDate",
    disableAfterApproved: true,
    type: "date"
  },
  {
    label: "First Payment Date",
    path: "loanInfo.firstPaymentDateOfLoan",
    field: "firstPaymentDateOfLoan",
    aliasKey: "firstPaymentDate",
    rulesKey: "firstPaymentDateOfLoan",
    disableAfterApproved: true,
    type: "date"
  },
  {
    label: "Last Payment Date",
    path: "loanDetailId.loanSummaryId.lastPaymentDate",
    field: "lastPaymentDate",
    type: "date",
    skipRules: true
  },
  {
    label: "Original Maturity Date",
    path: "loanInfo.originalMaturityDate",
    field: "originalMaturityDate",
    aliasKey: "maturityDate",
    rulesKey: "originalMaturityDate",
    disableAfterApproved: true,
    type: "date"
  },
  {
    label: "Total Origination and Discount Points (%)",
    path: "loanEconomics.totalOriginationAndDiscountPoints",
    field: "totalOriginationAndDiscountPoints",
    aliasKey: "discountPoints",
    rulesKey: "totalOriginationAndDiscountPoints",
    type: "percentage"
  },
  {
    label: "Cash Out Amount ($)",
    path: "loanEconomics.cashOutAmount",
    field: "cashOutAmount",
    type: "currency"
  },
  {
    label: "Default Rate (%)",
    path: "loanInfo.defaultRate",
    field: "defaultRate",
    type: "percentage",
    skipRules: true
  },
  {
    label: "Default Interest Rate Type",
    path: "loanEconomics.defaultInterestRateType",
    field: "defaultInterestRateType",
    type: "dropdown",
    values: ["Fixed", "Increasing"],
    skipRules: true
  },
  {
    label: "Default Rate Curable (Grace Days)",
    path: "loanInfo.defaultRateCurable",
    field: "defaultRateCurable",
    type: "number",
    skipRules: true
  },
  {
    label: "ARM Index",
    path: "loanEconomics.armIndex",
    field: "armIndex",
    type: "text",
    skipRules: true,
    hidden: (loanDetails: any) => {
      return loanDetails?.loanEconomics?.rateType !== "Adjustable";
    }
  },
  {
    label: "Gross Margin (%)",
    path: "loanEconomics.grossArmMargin",
    field: "grossArmMargin",
    type: "percentage",
    skipRules: true,
    hidden: (loanDetails: any) => {
      return loanDetails?.loanEconomics?.rateType !== "Adjustable";
    }
  },
  {
    label: "Arm Rounding Factor",
    path: "loanEconomics.armRoundingFactor",
    field: "armRoundingFactor",
    type: "Number",
    skipRules: true,
    hidden: (loanDetails: any) => {
      return loanDetails?.loanEconomics?.rateType !== "Adjustable";
    }
  },
  {
    label: "Initial Rate Adjustment Period",
    path: "loanEconomics.initialRateAdjustmentPeriod",
    field: "initialRateAdjustmentPeriod",
    type: "number",
    skipRules: true,
    hidden: (loanDetails: any) => {
      return loanDetails?.loanEconomics?.rateType !== "Adjustable";
    }
  },
  {
    label: "Initial Pay Adjustment Period",
    path: "loanEconomics.initialPayAdjustmentPeriod",
    field: "initialPayAdjustmentPeriod",
    type: "number",
    skipRules: true,
    hidden: (loanDetails: any) => {
      return loanDetails?.loanEconomics?.rateType !== "Adjustable";
    }
  },
  {
    label: "Initial Periodic Cap (%)",
    path: "loanEconomics.initialPeriodicCap",
    field: "initialPeriodicCap",
    type: "percentage",
    skipRules: true
  },
  {
    label: "Initial Periodic Cap (Change Up) (%)",
    path: "loanEconomics.initialInterestRateUp",
    field: "initialInterestRateUp",
    type: "percentage",
    skipRules: true
  },
  {
    label: "Initial Periodic Cap (Change Down) (%)",
    path: "loanEconomics.initialInterestRateDown",
    field: "initialInterestRateDown",
    type: "percentage",
    skipRules: true
  },
  {
    label: "Subsequent Periodic Cap (%)",
    path: "loanEconomics.subsequentPeriodicCap",
    field: "subsequentPeriodicCap",
    type: "percentage",
    skipRules: true
  },
  {
    label: "Subsequent Periodic Cap (Change Up) (%)",
    path: "loanEconomics.subsequentInterestRateUp",
    field: "subsequentInterestRateUp",
    type: "percentage",
    skipRules: true
  },
  {
    label: "Subsequent Periodic Cap (Change Down) (%)",
    path: "loanEconomics.subsequentInterestRateDown",
    field: "subsequentInterestRateDown",
    type: "percentage",
    skipRules: true
  },
  {
    label: "Lifetime Max Rate (%)",
    path: "loanEconomics.lifetimeMaxRate",
    field: "lifetimeMaxRate",
    type: "percentage",
    skipRules: true,
    hidden: (loanDetails: any) => {
      return loanDetails?.loanEconomics?.rateType !== "Adjustable";
    }
  },
  {
    label: "Lifetime Min Rate (%)",
    path: "loanEconomics.lifetimeMinRate",
    field: "lifetimeMinRate",
    type: "percentage",
    skipRules: true
  },
  {
    label: "Payment Adjustment Frequency",
    path: "loanEconomics.paymentAdjustmentFrequency",
    field: "paymentAdjustmentFrequency",
    type: "Number",
    skipRules: true,
    hidden: (loanDetails: any) => {
      return loanDetails?.loanEconomics?.rateType !== "Adjustable";
    }
  },
  {
    label: "Rate Adjustment Frequency",
    path: "loanEconomics.rateAdjustmentFrequency",
    field: "rateAdjustmentFrequency",
    type: "Number",
    skipRules: true,
    hidden: (loanDetails: any) => {
      return loanDetails?.loanEconomics?.rateType !== "Adjustable";
    }
  },
  {
    label: "Prepayment Penalty Total Term (Months)",
    path: "loanEconomics.prepaymentPenaltyMonths",
    field: "prepaymentPenaltyMonths",
    type: "number"
  },
  // {
  //   label: "Borrower is LLC",
  //   path: "loanEconomics.borrowerIsLLC",
  //   field: "borrowerIsLLC",
  //   type: "dropdown",
  //   values: yOrNDropDown,
  //   skipRules: true
  // },
  // {
  //   label: "HOA Payment Frequency",
  //   path: "loanEconomics.hoaPaymentFrequency",
  //   field: "hoaPaymentFrequency",
  //   type: "text",
  //   skipRules: true
  // },
  {
    label: "Borrower Liquidity ($)",
    path: "loanEconomics.borrowerLiquidity",
    field: "borrowerLiquidity",
    type: "currency",
    skipRules: true
  },
  {
    label: "Escrow Taxes at Closing ($)",
    path: "loanDetailId.loanSummaryId.escrowTaxes",
    field: "escrowTaxes",
    type: "readOnly",
    skipRules: true
  },
  {
    label: "Escrow Insurance at Closing ($)",
    path: "loanDetailId.loanSummaryId.escrowInsurance",
    field: "escrowInsurance",
    type: "readOnly",
    skipRules: true
  },
  {
    label: "Accrual Type",
    path: "loanEconomics.accrualType",
    field: "accrualType",
    type: "dropdown",
    values: accuralTypeDropDown
  },
  {
    label: "Subordinate Financing",
    path: "loanEconomics.subordinateFinancing",
    field: "subordinateFinancing",
    rulesKey: "subOrdinateFinancing",
    type: "yesnodropdown",
    values: yesNoDropdown
  },
  {
    label: "Business Purpose Occupancy",
    path: "loanEconomics.businessPurposeOccupancy",
    field: "businessPurposeOccupancy",
    type: "yesnodropdown",
    values: yesNoDropdown
  },
  {
    label: "T&I Collected",
    path: "loanDetailId.loanSummaryId.taxesAndInsuranceCollected",
    field: "taxesAndInsuranceCollected",
    type: "dropdown",
    values: yesNoDropdown,
    skipRules: true
  },
  {
    label: "T&I Due",
    path: "loanDetailId.loanSummaryId.taxesandInsuranceDue",
    field: "taxesandInsuranceDue",
    type: "dropdown",
    values: yesNoDropdown,
    skipRules: true
  },
  {
    label: "Updated Insurance Escrow Balance ($)",
    path: "loanDetailId.loanSummaryId.updatedInsuranceEscrowBalance",
    field: "updatedInsuranceEscrowBalance",
    type: "currency",
    skipRules: true
  },
  {
    label: "Updated Tax Escrow Balance ($)",
    path: "loanDetailId.loanSummaryId.updatedTaxEscrowBalance",
    field: "updatedTaxEscrowBalance",
    type: "currency",
    skipRules: true
  },
  {
    label: "ARM Round Flag",
    path: "loanEconomics.armRoundFlag",
    field: "armRoundFlag",
    type: "dropdown",
    values: yesNoDropdown,
    skipRules: true,
    hidden: (loanDetails: any) => {
      return loanDetails?.loanEconomics?.rateType !== "Adjustable";
    }
  },
  {
    label: "Late Charge ($)",
    path: "loanEconomics.lateCharge",
    field: "lateCharge",
    type: "currency",
    skipRules: true
  },
  {
    label: "Late Charge (%)",
    path: "loanEconomics.lateChargePercentage",
    field: "lateChargePercentage",
    type: "percentage",
    skipRules: true
  },
  {
    label: "ARM Look Back Days",
    path: "loanEconomics.armLookBackDays",
    field: "armLookBackDays",
    type: "number",
    skipRules: true,
    hidden: (loanDetails: any) => {
      return loanDetails?.loanEconomics?.rateType !== "Adjustable";
    }
  },
  {
    label: "Insurance Agent First Name",
    path: "loanDetailId.loanSummaryId.insuranceAgentFirstName",
    field: "insuranceAgentFirstName",
    type: "text",
    skipRules: true
  },
  {
    label: "Insurance Agent Last Name",
    path: "loanDetailId.loanSummaryId.insuranceAgentLastName",
    field: "insuranceAgentLastName",
    type: "text",
    skipRules: true
  },
  {
    label: "Insurance Agent Email",
    path: "loanDetailId.loanSummaryId.insuranceAgentEmail",
    field: "insuranceAgentEmail",
    type: "email",
    skipRules: true
  },
  {
    label: "Insurance Agent Contact Number",
    path: "loanDetailId.loanSummaryId.insuranceAgentContactNumber",
    field: "insuranceAgentContactNumber",
    type: "phone",
    skipRules: true
  },
  {
    label: "Insurance Expiration Date",
    path: "loanDetailId.loanSummaryId.insuranceExpirationDate",
    field: "insuranceExpirationDate",
    type: "dateOnly",
    skipRules: true
  },
  {
    label: "Insurance Company",
    path: "loanDetailId.loanSummaryId.insuranceCompany",
    field: "insuranceCompany",
    type: "text",
    skipRules: true
  },
  {
    label: "Insurance Type",
    path: "loanDetailId.loanSummaryId.insuranceType",
    field: "insuranceType",
    type: "text",
    skipRules: true
  },
  {
    label: "Insurance Company Address",
    path: "loanDetailId.loanSummaryId.insuranceCompanyAddress",
    field: "insuranceCompanyAddress",
    type: "billingaddress",
    skipRules: true
  },
  {
    label: "Policy Number",
    path: "loanDetailId.loanSummaryId.policyNumber",
    field: "policyNumber",
    type: "text",
    skipRules: true
  },
  {
    label: "Frequency",
    path: "loanDetailId.loanSummaryId.frequency",
    field: "frequency",
    type: "dropdown",
    values:["Monthly","Quarterly","Half-yearly","Yearly"],
    skipRules: true
  },
  {
    label: "Responsibility",
    path: "loanDetailId.loanSummaryId.responsibility",
    field: "responsibility",
    type: "dropdown",
    values:["Escrowed","Borrower"],
    skipRules: true
  },
  {
    label: "Interest Only",
    path: "loanEconomics.interestOnly",
    field: "interestOnly",
    type: "dropdown",
    values: yOrNDropDown,
    skipRules: true
  },
  {
    label: "P&I Payment ($)",
    path: "loanEconomics.originalMonthlyPiPayment",
    field: "originalMonthlyPiPayment",
    type: "currency",
    skipRules: true
  },
  {
    label: "Gross Amount Due from Borrower ($)",
    path: "loanEconomics.grossAmountDueFromBorrowerHUD",
    field: "grossAmountDueFromBorrowerHUD",
    type: "currency",
    skipRules: true
  },
  {
    label: "Origination Fee ($)",
    path: "loanFees.originationFeeHUD",
    field: "originationFeeHUD",
    type: "currency",
    skipRules: true
  },
  {
    label: "Broker Fee ($)",
    path: "loanFees.brokerFeeHUD",
    field: "brokerFeeHUD",
    type: "currency",
    skipRules: true
  },
  {
    label: "Assignment Fee ($)",
    path: "loanFees.assignmentFeeHUD",
    field: "assignmentFeeHUD",
    type: "currency",
    skipRules: true
  },
  {
    label: "Per Diem Interest ($)",
    path: "loanFees.perDiemInterestHUD",
    field: "perDiemInterestHUD",
    type: "currency",
    skipRules: true
  },
  {
    label: "Flood Insurance Coverage ($)",
    path: "loanEconomics.floodInsuranceCoverage",
    field: "floodInsuranceCoverage",
    type: "currency",
    skipRules: true
  },
  {
    label: "HOI Dwelling Coverage ($)",
    path: "loanEconomics.hoiDwellingCoverage",
    field: "hoiDwellingCoverage",
    type: "currency",
    skipRules: true
  },
  {
    label: "ACH Activation Type",
    path: "achData.achActivationType",
    field: "achActivationType",
    type: "text",
    skipRules: true
  },
  {
    label: "Bank ABA Routing Number",
    path: "achData.bankABARoutingNumber",
    field: "bankABARoutingNumber",
    type: "abaroutingnumber",
    skipRules: true
  },
  {
    label: "Bank Name",
    path: "achData.bankName",
    field: "bankName",
    type: "text",
    skipRules: true
  },
  {
    label: "Bank City",
    path: "achData.bankCity",
    field: "bankCity",
    type: "text",
    skipRules: true
  },
  {
    label: "Bank State",
    path: "achData.bankState",
    field: "bankState",
    type: "dropdown",
    values: USStates,
    skipRules: true
  },
  {
    label: "Bank Account Number",
    path: "achData.bankAccountNumber",
    field: "bankAccountNumber",
    type: "Number",
    skipRules: true
  },
  {
    label: "Bank Account Type",
    path: "achData.bankAccountType",
    field: "bankAccountType",
    type: "dropdown",
    values: ["Savings Account", "Checking Account"],
    skipRules: true
  },
  {
    label: "PreNote",
    path: "achData.preNote",
    field: "preNote",
    type: "text",
    skipRules: true
  },
  {
    label: "Name on Account Indicator",
    path: "achData.nameOnAccountIndicator",
    field: "nameOnAccountIndicator",
    type: "dropdown",
    values: ["B", "C", "3"],
    skipRules: true
  },
  {
    label: "Drafting Option",
    path: "achData.draftingOption",
    field: "draftingOption",
    type: "dropdown",
    values: ["Monthly", "Bi-Weekly", "Weekly", "Semi-Monthly"],
    skipRules: true
  },
  {
    label: "Draft Day 1",
    path: "achData.draftDay1",
    field: "draftDay1",
    type: "dateOnly",
    skipRules: true
  },
  {
    label: "Draft Day 2",
    path: "achData.draftDay2",
    field: "draftDay2",
    type: "dateOnly",
    skipRules: true
  },
  {
    label: "Desired Start Date",
    path: "achData.desiredStartDate",
    field: "desiredStartDate",
    type: "dateOnly",
    skipRules: true
  },
  {
    label: "Additional Principal",
    path: "achData.additionalPrincipal",
    field: "additionalPrincipal",
    type: "currency",
    skipRules: true
  },
  {
    label: "Additional Escrow",
    path: "achData.additionalEscrow",
    field: "additionalEscrow",
    type: "currency",
    skipRules: true
  },
  {
    label: "Additional Other",
    path: "achData.additionalOther",
    field: "additionalOther",
    type: "currency",
    skipRules: true
  },
  {
    label: "Draft Fee",
    path: "achData.draftFee",
    field: "draftFee",
    type: "currency",
    skipRules: true
  },
  {
    label: "Fixed Draft Amount",
    path: "achData.fixedDraftAmount",
    field: "fixedDraftAmount",
    type: "currency",
    skipRules: true
  },
  {
    label: "Next Transfer Date",
    path: "achData.nextTransferDate",
    field: "nextTransferDate",
    type: "dateOnly",
    skipRules: true
  },
  {
    label: "Last Draft Date",
    path: "achData.lastDraftDate",
    field: "lastDraftDate",
    type: "dateOnly",
    skipRules: true
  },
  {
    label: "DSCR",
    path: "loanResults.loanResult.[loanId].loanEconomics.dscr",
    field: "dscr",
    type: "readOnly",
    skipRules: true
  }
];

export const BridgeUserFields = [
  {
    label: "Entity Name",
    path: "customer.accountName",
    borrowerAliasKey: "borrowingEntityName",
    guarantorAliasKey: "borrowingEntityName",
    field: "accountName",
    rulesKey: "partyType",
    type: "text",
    partyType: "account"
  },
  {
    label: "% of Ownership of Entity",
    path: "ownershipOfEntity",
    guarantorAliasKey: "pOEntity",
    rulesKey: "ownershipOfEntity",
    field: "ownershipOfEntity",
    type: "percentage",
  },
  {
    label: "First Name",
    field: "firstName",
    path: "customer.firstName",
    borrowerAliasKey: "firstName",
    guarantorAliasKey: "guarantorFirstName",
    type: "text",
    partyType: "person",
    skipRules: true
  },
  {
    label: "Last Name",
    field: "lastName",
    path: "customer.lastName",
    borrowerAliasKey: "lastName",
    guarantorAliasKey: "guarantorLastName",
    type: "text",
    partyType: "person",
    skipRules: true
  },
  {
    label: "Foreign National",
    field: "foreignNationalString",
    path: "customer.foreignNationalString",
    borrowerAliasKey: "foreignNationalString",
    guarantorAliasKey: "foreignNationalString",
    rulesKey: "foreignNationalString",
    type: "dropdown",
    values: yesNoDropdown
  },
  {
    label: "Original Credit Score Date",
    field: "originalCreditScoreReportDate",
    path: "originalCreditScoreReportDate",
    borrowerAliasKey: "originalCreditReportDate",
    guarantorAliasKey: "originalCreditReportDate",
    ddIdentifier: "originalCreditScoreReportDate",
    rulesKey: "originalCreditScoreReportDate",
    disableAfterApproved: true,
    type: "date",
    partyType: "person"
  },
  {
    label: "Original Credit Score (Median)",
    field: "originalCreditScoreMedian",
    path: "originalCreditScoreMedian",
    borrowerAliasKey: "originalCreditScoreMedian",
    guarantorAliasKey: "creditScore",
    rulesKey: "originalCreditScoreMedian",
    disableAfterApproved: true,
    type: "creditscore",
    partyType: "person"
  },
  {
    label: "Billing Phone Number",
    field: "contactNumber",
    path: "customer.contactList.0.contactNumber",
    auditPath: "customer.contactList[0].contactNumber",
    borrowerAliasKey: "billingPhoneNumber",
    skipRules: true,
    type: "phone"
  },
  {
    label: "Email",
    field: "email",
    path: "customer.contactList.0.email",
    auditPath: "customer.contactList[0].email",
    borrowerAliasKey: "borrowerEmail",
    type: "email",
    skipRules: true
  },
  {
    label: "Experience",
    field: "experience",
    path: "experience",
    borrowerAliasKey: "borrowerExperience",
    rulesKey: "borrowerExperience",
    type: "number"
  },
  {
    label: "Full Billing Address",
    field: "addressLine1",
    path: "customer.addressList.0.addressLine1",
    auditPath: "customer.addressList[0].addressLine1",
    borrowerAliasKey: "billingAddress",
    skipRules: true,
    type: "billingaddress"
  },
  {
    label: "EIN",
    path: "customer.eiNumber",
    field: "eiNumber",
    borrowerAliasKey: "eiNumber",
    guarantorAliasKey: "eiNumber",
    type: "ein",
    skipRules: true,
    partyType: "account"
  },
  {
    label: "SSN",
    path: "customer.ssNumber",
    field: "ssNumber",
    borrowerAliasKey: "ssNumber",
    guarantorAliasKey: "ssNumber",
    type: "ssn",
    skipRules: true,
    partyType: "person"
  },
  {
    label: "ID Expiration Date",
    path: "customer.passportExpirationDate",
    field: "passportExpirationDate",
    borrowerAliasKey: "passportExpirationDate",
    guarantorAliasKey: "passportExpirationDate",
    type: "dateOnly",
    skipRules: true,
    partyType: "person"
  }
];

export const DSCRUserFields = [
  {
    label: "Entity Name",
    path: "customer.accountName",
    borrowerAliasKey: "borrowingEntityName",
    guarantorAliasKey: "borrowingEntityName",
    field: "accountName",
    rulesKey: "partyType",
    type: "text",
    partyType: "account"
  },
  {
    label: "% of Ownership of Entity",
    path: "ownershipOfEntity",
    guarantorAliasKey: "pOEntity",
    rulesKey: "ownershipOfEntity",
    field: "ownershipOfEntity",
    type: "percentage",
  },
  {
    label: "First Name",
    field: "firstName",
    path: "customer.firstName",
    borrowerAliasKey: "firstName",
    guarantorAliasKey: "guarantorFirstName",
    type: "text",
    partyType: "person",
    skipRules: true
  },
  {
    label: "Last Name",
    field: "lastName",
    path: "customer.lastName",
    borrowerAliasKey: "lastName",
    guarantorAliasKey: "guarantorLastName",
    type: "text",
    partyType: "person",
    skipRules: true
  },
  {
    label: "Foreign National",
    field: "foreignNationalString",
    path: "customer.foreignNationalString",
    borrowerAliasKey: "foreignNationalString",
    guarantorAliasKey: "foreignNationalString",
    rulesKey: "foreignNationalString",
    type: "dropdown",
    values: foreignNationalOptions
  },
  {
    label: "Original Credit Score Date",
    field: "originalCreditScoreReportDate",
    path: "originalCreditScoreReportDate",
    borrowerAliasKey: "originalCreditReportDate",
    guarantorAliasKey: "originalCreditReportDate",
    ddIdentifier: "originalCreditScoreReportDate",
    rulesKey: "originalCreditScoreReportDate",
    disableAfterApproved: true,
    type: "date",
    partyType: "person"
  },
  {
    label: "Original Credit Score (Median)",
    field: "originalCreditScoreMedian",
    path: "originalCreditScoreMedian",
    borrowerAliasKey: "originalCreditScoreMedian",
    guarantorAliasKey: "creditScore",
    rulesKey: "originalCreditScoreMedian",
    disableAfterApproved: true,
    type: "creditscore",
    partyType: "person"
  },
  {
    label: "Billing Phone Number",
    field: "contactNumber",
    path: "customer.contactList.0.contactNumber",
    auditPath: "customer.contactList[0].contactNumber",
    borrowerAliasKey: "billingPhoneNumber",
    type: "phone",
    skipRules: true
  },
  {
    label: "Email",
    field: "email",
    path: "customer.contactList.0.email",
    auditPath: "customer.contactList[0].email",
    borrowerAliasKey: "borrowerEmail",
    type: "email",
    skipRules: true
  },
  // {
  //   label: "Experience",
  //   field: "experience",
  //   path: "experience",
  //   borrowerAliasKey: "borrowerExperience",
  //   rulesKey: "borrowerExperience",
  //   type: "number"
  // },
  {
    label: "Full Billing Address",
    field: "addressLine1",
    path: "customer.addressList.0.addressLine1",
    auditPath: "customer.addressList[0].addressLine1",
    borrowerAliasKey: "billingAddress",
    skipRules: true,
    type: "billingaddress"
  },
  {
    label: "EIN",
    path: "customer.eiNumber",
    field: "eiNumber",
    borrowerAliasKey: "eiNumber",
    guarantorAliasKey: "eiNumber",
    type: "ein",
    skipRules: true,
    partyType: "account"
  },
  {
    label: "SSN",
    path: "customer.ssNumber",
    field: "ssNumber",
    borrowerAliasKey: "ssNumber",
    guarantorAliasKey: "ssNumber",
    type: "ssn",
    skipRules: true,
    partyType: "person"
  },
  {
    label: "ID Expiration Date",
    path: "customer.passportExpirationDate",
    field: "passportExpirationDate",
    borrowerAliasKey: "passportExpirationDate",
    guarantorAliasKey: "passportExpirationDate",
    type: "dateOnly",
    skipRules: true,
    partyType: "person"
  }
];

export const BridgePropertyFields = [
  {
    label: "Property Type",
    path: "propertyinfo.propertyType",
    field: "propertyType",
    type: "dropdown",
    values: propertyTypeDropDown.map((val) =>
      val === "SFR" ? "Single Family Detached" : val
    )
  },
  // {
  //   label: "Appraisal Reported NOI/NCF ($)",
  //   path: "propertyEconomics.appraisalReportedNoiNcf",
  //   field: "appraisalReportedNoiNcf",
  //   aliasKey: "appraisalReported",
  //   rulesKey: "appraisalReportedNoiNcf",
  //   type: "currency"
  // },
  // {
  //   label: "% Occupied (%)",
  //   path: "propertyinfo.occupiedPercentage",
  //   field: "occupiedPercentage",
  //   aliasKey: "percentageOccupied",
  //   rulesKey: "percentOccupied",
  //   type: "percentage"
  // },
  // {
  //   label: "Change in Use Case",
  //   path: "propertyinfo.changeInUseCase",
  //   field: "changeInUseCase",
  //   type: "dropdown",
  //   values: yOrNDropDown
  // },
  // {
  //   label: "Gross Potential Rent ($)",
  //   path: "propertyEconomics.grossPotentialRent",
  //   field: "grossPotentialRent",
  //   type: "currency"
  // },
  // {
  //   label: "Rental Cashflow Ratio",
  //   path: "propertyEconomics.rentalCashflowRatio",
  //   field: "rentalCashflowRatio",
  //   type: "rentalCashflowRatio",
  //   skipRules: true
  // },
  {
    label: `Original "As Is" Appraisal Value ($)`,
    path: "propertyEconomics.originalAsIsAppraisalValue",
    field: "originalAsIsAppraisalValue",
    aliasKey: "originalAppraisalValue",
    rulesKey: "originalAsIsAppraisalValue",
    disableAfterApproved: true,
    type: "currency"
  },
  {
    label: "Property Acquisition Date",
    path: "propertyinfo.propertyAcquisitionDate",
    field: "propertyAcquisitionDate",
    aliasKey: "propertyAcquisitionDate",
    rulesKey: "propertyAcquisitionDate",
    type: "dateOnly"
  },
  {
    label: `Original "As Repaired" Appraisal Value ($)`,
    path: "propertyEconomics.originalAsRepairedAppraisedValue",
    field: "originalAsRepairedAppraisedValue",
    aliasKey: "originalRepairedAppraisalValue",
    rulesKey: "originalRepairedAppraisalValue",
    disableAfterApproved: true,
    type: "currency"
  },
  {
    label: "Third Party Valuation Date",
    path: "propertyinfo.propertyValuationDate",
    field: "propertyValuationDate",
    aliasKey: "originalPropertyValuationDate",
    skipRules: true,
    type: "dateOnly"
  },
  {
    label: "Most Recent Property Valuation Date",
    path: "propertyinfo.recentPropertyValuationDate",
    field: "recentPropertyValuationDate",
    aliasKey: "propertyValuationDate",
    rulesKey: "mostRecentPropertyValuationDate",
    type: "dateOnly"
  },
  {
    label: "Purchase Price ($)",
    path: "propertyEconomics.purchasePrice",
    field: "purchasePrice",
    aliasKey: "purchasePrice",
    rulesKey: "purchasePrice",
    disableAfterApproved: true,
    type: "currency"
  },
  {
    label: "Cost Basis ($)",
    path: "propertyEconomics.costBasis",
    field: "costBasis",
    aliasKey: "costBasis",
    rulesKey: "costBasisAfterCostIncurred",
    disableAfterApproved: true,
    type: "currency"
  },
  {
    label: "Pre-Rehab Square Footage",
    path: "propertyinfo.preRehabSquareFootage",
    field: "preRehabSquareFootage",
    aliasKey: "preRehabSquareFootage",
    rulesKey: "preRehabSquareFootage",
    type: "Number"
  },
  {
    label: "Post-Rehab Square Footage",
    path: "propertyinfo.postRehabSquareFootage",
    field: "postRehabSquareFootage",
    aliasKey: "postRehabSquareFootage",
    rulesKey: "postRehabSquareFootage",
    type: "Number"
  },
  {
    label: "Address Line 1",
    path: "propertyLocation.addressLine1",
    field: "addressLine1",
    aliasKey: "street_line",
    type: "autoProperty"
  },
  {
    label: "City",
    path: "propertyLocation.city",
    field: "city",
    type: "text"
  },
  {
    label: "State",
    path: "propertyLocation.state",
    field: "state",
    type: "dropdown",
    values: USStates
  },
  {
    label: "Postal Code",
    path: "propertyLocation.postalCode",
    field: "postalCode",
    aliasKey: "zipCode",
    rulesKey: "postalCode",
    type: "postalcode"
  },
  {
    label: "Appraisal Type",
    path: "propertyinfo.appraisalType",
    field: "appraisalType",
    type: "text",
    skipRules: true
  },
  {
    label: "Appraiser Name",
    path: "propertyinfo.appraisalVendor",
    field: "appraisalVendor",
    type: "text",
    skipRules: true
  },
  {
    label: "Appraisal Certification / License",
    path: "propertyinfo.appraisalCertificationOrLicense",
    field: "appraisalCertificationOrLicense",
    type: "text",
    skipRules: true
  },
  {
    label: "Appraisal Certification / License Expiration Date",
    path: "propertyinfo.appraisalExpirationDate",
    field: "appraisalExpirationDate",
    type: "dateOnly",
    skipRules: true
  },
  {
    label: "Appraisal State",
    path: "propertyinfo.appraisalState",
    field: "appraisalState",
    type: "dropdown",
    values: USStates,
    skipRules: true
  },
  {
    label: "Pre-Rehab Units",
    path: "propertyinfo.preRehabUnits",
    field: "preRehabUnits",
    type: "number",
    skipRules: true
  },
  {
    label: "Post-Rehab Units",
    path: "propertyinfo.postRehabUnits",
    field: "postRehabUnits",
    type: "number",
    skipRules: true
  },
  {
    label: "Annual Property Taxes ($)",
    path: "propertyEconomics.annualPropertyTaxes",
    field: "annualPropertyTaxes",
    type: "currency"
  }
];

export const DSCRPropertyFields = [
  {
    label: "Property Type",
    path: "propertyinfo.propertyType",
    field: "propertyType",
    type: "dropdown",
    values: thirtyYearPropertyTypeDropDown.map((val) =>
      val === "SFR" ? "Single Family Detached" : val
    )
  },
  // {
  //   label: "% Occupied (%)",
  //   path: "propertyinfo.occupiedPercentage",
  //   field: "occupiedPercentage",
  //   aliasKey: "percentageOccupied",
  //   rulesKey: "percentOccupied",
  //   type: "percentage"
  // },
  {
    label: `Original "As Is" Appraisal Value ($)`,
    path: "propertyEconomics.originalAsIsAppraisalValue",
    field: "originalAsIsAppraisalValue",
    aliasKey: "originalAppraisalValue",
    rulesKey: "originalAsIsAppraisalValue",
    disableAfterApproved: true,
    type: "currency"
  },
  {
    label: "Property Acquisition Date",
    path: "propertyinfo.propertyAcquisitionDate",
    field: "propertyAcquisitionDate",
    aliasKey: "propertyAcquisitionDate",
    rulesKey: "propertyAcquisitionDate",
    type: "dateOnly"
  },
  {
    label: "Third Party Valuation ($)",
    path: "propertyEconomics.thirdPartyValuation",
    field: "thirdPartyValuation",
    aliasKey: "thirdPartyValuation",
    rulesKey: "thirdPartyValuation",
    type: "currency"
  },
  {
    label: "Purchase Price ($)",
    path: "propertyEconomics.purchasePrice",
    field: "purchasePrice",
    aliasKey: "purchasePrice",
    rulesKey: "purchasePrice",
    disableAfterApproved: true,
    type: "currency"
  },
  {
    label: "Cost Basis ($)",
    path: "propertyEconomics.costBasis",
    field: "costBasis",
    aliasKey: "costBasis",
    rulesKey: "costBasisAfterCostIncurred",
    disableAfterApproved: true,
    type: "currency"
  },
  {
    label: [
      "Rental Characterisation",
      "Current Lease Term",
      "Market Rent Monthly",
      "In Place Lease Rent Monthly",
      "Most Recent Lease Rent Monthly",
      "Third Party Rent Range Amount"
    ],
    path: "propertyUnit",
    key: [
      "leaseStatus",
      "currentLeaseTermMonthly",
      "marketRentMonthly",
      "inPlaceLeaseRentMonthly",
      "mostRecentLeaseRentMonthly",
      "thirdPartyRentRangeAmount"
    ],
    field: [
      "leaseStatus",
      "currentLeaseTermMonthly",
      "marketRentMonthly",
      "inPlaceLeaseRentMonthly",
      "mostRecentLeaseRentMonthly",
      "thirdPartyRentRangeAmount"
    ],
    aliasKey: [
      "leaseStatus",
      "currentLeaseTerm",
      "marketRentMonthly",
      "inPlaceLeaseRent",
      "recentLeaseRent",
      "thirdPartyRentRangeAmount"
    ],
    rulesKey: [
      "leaseStatus",
      "currentLeaseTermMonths",
      "marketRentMonthly",
      "inPlaceLeaseRentMonthly",
      "mostRecentLeaseRentMonthly",
      "thirdPartyRentRangeAmount"
    ],
    type: [
      "dropdown",
      "Number",
      "currency",
      "currency",
      "currency",
      "currency"
    ],
    values: [
      "leaseStatusDropDown",
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    ],
    skipRules: [false, false, false, false, false, true]
  },
  {
    label: "Annual Property Taxes ($)",
    path: "propertyEconomics.annualPropertyTaxes",
    field: "annualPropertyTaxes",
    type: "currency"
  },
  {
    label: "Annual Hazard Insurance ($)",
    path: "propertyEconomics.annualHazardInsurance",
    field: "annualHazardInsurance",
    type: "currency"
  },
  {
    label: "Annual Flood Insurance ($)",
    path: "propertyEconomics.annualFloodInsurance",
    field: "annualFloodInsurance",
    type: "currency"
  },
  {
    label: "Annual HOA Fee ($)",
    path: "propertyEconomics.annualHoaFee",
    field: "annualHoaFee",
    aliasKey: "annualHoaFee",
    rulesKey: "annualHOAFee",
    type: "currency"
  },
  {
    label: "Address Line 1",
    path: "propertyLocation.addressLine1",
    field: "addressLine1",
    aliasKey: "street_line",
    type: "autoProperty"
  },
  {
    label: "City",
    path: "propertyLocation.city",
    field: "city",
    type: "text"
  },
  {
    label: "State",
    path: "propertyLocation.state",
    field: "state",
    type: "dropdown",
    values: USStates
  },
  {
    label: "Postal Code",
    path: "propertyLocation.postalCode",
    field: "postalCode",
    aliasKey: "zipCode",
    rulesKey: "postalCode",
    type: "postalcode"
  },
  {
    label: "Escrow Taxes at Closing ($)",
    path: "propertySummary.escrowTaxes",
    field: "escrowTaxes",
    type: "readOnly",
    skipRules: true
  },
  {
    label: "Escrow Insurance at Closing ($)",
    path: "propertySummary.escrowInsurance",
    field: "escrowInsurance",
    type: "readOnly",
    skipRules: true
  },
  {
    label: "Collateral ID",
    path: "propertyinfo.collateralId",
    field: "collateralId",
    type: "alphanumeric",
    skipRules: true
  },
  {
    label: "Appraisal Type",
    path: "propertyinfo.appraisalType",
    field: "appraisalType",
    type: "text",
    skipRules: true
  },
  {
    label: "Appraiser Name",
    path: "propertyinfo.appraisalVendor",
    field: "appraisalVendor",
    type: "text",
    skipRules: true
  },
  {
    label: "Appraisal Certification / License",
    path: "propertyinfo.appraisalCertificationOrLicense",
    field: "appraisalCertificationOrLicense",
    type: "text",
    skipRules: true
  },
  {
    label: "Appraisal Certification / License Expiration Date",
    path: "propertyinfo.appraisalExpirationDate",
    field: "appraisalExpirationDate",
    type: "dateOnly",
    skipRules: true
  },
  {
    label: "Appraisal State",
    path: "propertyinfo.appraisalState",
    field: "appraisalState",
    type: "dropdown",
    values: USStates,
    skipRules: true
  },
  {
    label: "Original Property Valuation Type",
    path: "propertyinfo.propertyValuationType",
    field: "propertyValuationType",
    type: "dropdown",
    values: ["As-Is", "ARV", "Subject To"],
    skipRules: true
  },
  {
    label: "Third Party Valuation Date",
    path: "propertyinfo.propertyValuationDate",
    field: "propertyValuationDate",
    aliasKey: "originalPropertyValuationDate",
    skipRules: true,
    type: "dateOnly"
  },
  {
    label: "Most Recent Property Valuation Date",
    path: "propertyinfo.recentPropertyValuationDate",
    field: "recentPropertyValuationDate",
    aliasKey: "propertyValuationDate",
    rulesKey: "mostRecentPropertyValuationDate",
    type: "dateOnly"
  }
];
