import { TextareaAutosize, TextField } from "@mui/material";
import React from "react";
import { LineItem } from "../../stores/budgetReview/budgetReview.interface";
import { HtmlWhiteTooltip } from "../../utils/Tooltip";
import { useBudgetStyles } from "../BudgetReview.style";

interface BudgetDescriptionProps {
  row: any;
  isDisabled: boolean;
  setPopUpOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showUnitField: boolean;
  isPropertyMapped: boolean;
  textareaRefs: any;
  fieldsData: LineItem[];
  setFieldsData: React.Dispatch<React.SetStateAction<LineItem[]>>;
  handleItemOnBlur: () => void;
  handleFieldChange: (
    event: any,
    id: string | null,
    field: string,
    dropdownField?: string | undefined
  ) => void;
}

export function BudgetDescription({
  row,
  isDisabled,
  setPopUpOpen,
  showUnitField,
  isPropertyMapped,
  textareaRefs,
  fieldsData,
  setFieldsData,
  handleItemOnBlur,
  handleFieldChange
}: BudgetDescriptionProps) {
  const budgetClasses = useBudgetStyles();
  const handleDescTextFieldClick = (id: any, val: boolean) => {
    if (isDisabled) return;
    if (!isPropertyMapped) {
      setPopUpOpen(true);
      return;
    }
    if (textareaRefs.current[id]) {
      textareaRefs.current[id].focus();
    }
    const updatedData = fieldsData.map((item: LineItem) => {
      return item.id === id
        ? {
            ...item,
            isDescEditMode: val
          }
        : item;
    });
    setFieldsData(updatedData);
    if (textareaRefs.current[id]) {
      textareaRefs.current[id].focus();
    }
  };

  return (
    <>
      {!row.isDescEditMode ? (
        <>
          <HtmlWhiteTooltip title={row.description} placement="right" arrow>
            <TextField
              variant="outlined"
              data-testid={`description`}
              value={row.description}
              className={`${
                isDisabled
                  ? budgetClasses.lineItemTextFieldDisabled
                  : budgetClasses.lineItemTextField
              } description-text`}
              style={{
                width: "98%"
              }}
              onClick={() => handleDescTextFieldClick(row.id, true)}
              disabled={isDisabled}
            />
          </HtmlWhiteTooltip>
        </>
      ) : (
        <TextareaAutosize
          minRows={3}
          data-testid={`description-textarea`}
          value={row.description}
          className={budgetClasses.textAreaDescContainer}
          style={{
            width: showUnitField ? "100%" : "95%"
          }}
          onChange={(e) => handleFieldChange(e, row.id, "description")}
          disabled={isDisabled}
          onBlur={() => {
            if (!isPropertyMapped) {
              setPopUpOpen(true);
              return;
            }
            handleDescTextFieldClick(row.id, false);
            handleItemOnBlur();
          }}
        />
      )}
    </>
  );
}
