import React, { useEffect, useMemo, useState } from "react";
import { PricingAndGuidlinesSectionModalBody } from "./PricingAndGuidlinesSectionModalBody";
import { ProgrammeSelectionModalWrapper } from "./ProgrammeSelectionModalWrapper";
import { Button, Link } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { getWaiverList } from "../../../stores/EvaluationResults/EvaluationResults.action";
import { RootState } from "../../../stores/rootReducer";
import { updateTakeOutPartnerData } from "../../../network/apiService";
import { isLATUser } from "../../../utils/AccessManagement";
import { resultsWithoutWaiver } from "../ResultsListWithFailMessages";
import { ObjectType } from "../../../masterView/common";

export interface loanDataInterface {
  // loanStageForWaiver: LoanStage;
  loanIdForWaiver: string;
}

interface PricingAndGuidlinesSectionModalProps {
  popUpOpen: boolean;
  setPopUpOpen: (val: boolean) => void;
  rateSheetTypeData: any;
  submitLoanWithTakeOutPartner: (val?:string) => void;
  loansWithoutAssignedPartner?: any[];
  criticalTests?: ObjectType;
  // Add other specific props here
}

function checkSubmitDisabled(
  selectedProgramDetails: { key: any },
  waiversCreated: any[],
  globalFilteredPartners: any[]
) {
  const selectedProgId = selectedProgramDetails?.key;
  if (
    !(
      // waiversCreated?.length &&
      (globalFilteredPartners?.length && selectedProgId)
    )
  )
    return true;
  const waiversCreatedFiltered = waiversCreated.filter(
    (it: any) => it?.takeoutPartnerId === selectedProgId
  );
  const partnersResultFiltered = globalFilteredPartners.find(
    (it: any) => it.key === selectedProgId
  );
  if (!partnersResultFiltered) return false;

  // critical tests logic
  const isCriticalTestFailed = partnersResultFiltered?.fail?.filter(
    (item: any) =>
      item.isCriticalTest && item.waiverDetails?.waiverStatus === "Requested"
  );
  const totalCriticalFailedTests = partnersResultFiltered?.fail?.filter(
    (item: any) => item.isCriticalTest
  );

  if (selectedProgId) {
    if (isCriticalTestFailed?.length !== totalCriticalFailedTests?.length) {
      return true;
    }
    return false;
  }

  const partnersResultFilteredWithoutWaiver = partnersResultFiltered.fail
    .filter(
      (item: any) => !resultsWithoutWaiver.includes(item.label.toLowerCase())
    )
    .filter((item: any) => item?.waiverDetails?.waiverStatus !== "Requested");

  const openWaivers = waiversCreatedFiltered.filter(
    (waiverDetail: any) =>
      !["APPROVED", "APPROVED-CONDITIONALLY", "REJECTED", ""].includes(
        waiverDetail?.waiverInfo?.finalResponse
      )
  );
  return !!partnersResultFilteredWithoutWaiver.length || !!openWaivers.length;
}

export function PricingAndGuidlinesSectionModal(
  props: PricingAndGuidlinesSectionModalProps
) {
  const dispatch = useDispatch();
  const { loanStage, loanId } = useParams<{
    loanId: any;
    loanStage: any;
    loanType: any;
  }>();

  const {
    popUpOpen,
    setPopUpOpen,
    rateSheetTypeData,
    submitLoanWithTakeOutPartner,
    loansWithoutAssignedPartner,
    criticalTests
  } = props;
  const [showWaiverPage, setShowWaiverPage] = useState<boolean>(false);
  const [waiverDetails, setWaiverDetails] = useState<any>({});
  const [loanDataForWaiver, setLoanDataForWaiver] =
    useState<loanDataInterface | null>(null);
  const { loanIdForWaiver } = loanDataForWaiver || {};
  const [globalFilteredPartners, setGlobalFilteredPartners] = useState<any[]>(
    []
  );
  const [dropDownLoanOptions, setDropDownLoanOptions] = useState<any[]>([]);
  const [selectedLoan, setSelectedLoan] = useState<any>({});
  const [selectedProgramDetails, setSelectedProgramDetails] = useState<any>({});
  const { waiversCreated } = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore
  );
  const excelData = sessionStorage.getItem("excelUpload");

  useEffect(() => {
    if (!loansWithoutAssignedPartner?.length) return;
    //check redux data, if there update setvariable & return else conti
    const options = loansWithoutAssignedPartner.map((loan: any) => {
      return {
        label: `${loan.loanId} / ${loan.excelData.primaryLoanId}`,
        value: loan.loanId,
        waiverDetails: waiversCreated,
        selectedProgramDetails,
        isReadyForSubmit: false,
        filteredPartners: []
      };
    });
    setLoanDataForWaiver({ loanIdForWaiver: options[0].value });
    setDropDownLoanOptions(options);
    // eslint-disable-next-line
  }, [loansWithoutAssignedPartner]);

  const handleRequestWaiverClick = (waiverDetails: any) => {
    setShowWaiverPage(true);
    setWaiverDetails({ ...waiverDetails });
  };

  useEffect(() => {
    if (!loansWithoutAssignedPartner?.length) {
      setLoanDataForWaiver({
        loanIdForWaiver: loanId
      });
    }
  }, [loanId, loansWithoutAssignedPartner]);

  useEffect(() => {
    if (!loanDataForWaiver?.loanIdForWaiver) return;
    dispatch(
      getWaiverList(
        loansWithoutAssignedPartner?.length
          ? loanDataForWaiver.loanIdForWaiver
          : loanId,
        loanStage,
        true
      )
    );
    // eslint-disable-next-line
  }, [dispatch, loanDataForWaiver?.loanIdForWaiver]);

  useEffect(() => {
    if (!(excelData && globalFilteredPartners.length)) return;
    if (selectedLoan && Object.keys(selectedLoan).length === 0) return;
    const selectedLoanNew = { ...selectedLoan };
    selectedLoanNew.filteredPartners = globalFilteredPartners;
    setSelectedLoan(selectedLoanNew);
    const dropDownLoanOptionsNew = JSON.parse(
      JSON.stringify(dropDownLoanOptions)
    );
    const index = dropDownLoanOptions.findIndex(
      (obj: { value: string }) => obj.value === selectedLoan.value
    );
    if (index !== -1) {
      dropDownLoanOptionsNew[index].filteredPartners = globalFilteredPartners;
    }
    setDropDownLoanOptions(dropDownLoanOptionsNew);

    // eslint-disable-next-line
  }, [globalFilteredPartners]);

  //**
  //
  // useEffect(() => {
  //   if (!excelData) return;
  //   if (!(globalFilteredPartners.length && waiversCreated.length)) return;
  //   const newSelected = dropDownLoanOptions.find(
  //     (it: any) => it.value === selectedLoan.value
  //   );
  //   if (newSelected && Object.keys(newSelected.selectedProgramDetails).length)
  //     return;
  //   const defaultProgram = globalFilteredPartners.find(item => {
  //     if (!item?.fail?.length) return false;
  //     const waiversCreatedFiltered = waiversCreated.filter(
  //       (it: any) => it.takeoutPartnerId === item.key
  //     );
  //     let partnersResultFilteredWithoutWaiver =
  //       item?.fail?.length &&
  //       item.fail.filter(
  //         (failTest: any) =>
  //           !resultsWithoutWaiver.includes(failTest.label.toLowerCase())
  //       );
  //     console.log(waiversCreatedFiltered, partnersResultFilteredWithoutWaiver);
  //     return waiversCreatedFiltered.length &&
  //       partnersResultFilteredWithoutWaiver
  //       ? partnersResultFilteredWithoutWaiver.length ===
  //           waiversCreatedFiltered.length
  //       : false;
  //   });
  //   if (!!defaultProgram) {
  //     setSelectedProgramDetails(defaultProgram);
  //   } else {
  //     setSelectedProgramDetails({});
  //   }
  //   // eslint-disable-next-line
  // }, [globalFilteredPartners, waiversCreated]);

  const partnertype = useMemo(() => {
    return isLATUser() ? "Partner" : "Program";
  }, []);

  const isSubmitDisabled = useMemo(() => {
    if (excelData) {
      /**
       * loop through the list and check for all the datain the list
       * update the isSReaduForSubmit:boolean based on the results
       * return true if for all the element resp is true
       */
      if (
        !(
          dropDownLoanOptions.length &&
          Object.keys(selectedLoan.selectedProgramDetails).length
        )
      ) {
        return true;
      }
      //removing this as sessionStorage holds the old data & not the new one
      //
      // let isLoanSubmittionReadyForBulk = sessionStorage.getItem(
      //   "isLoanSubmittionReadyForBulk"
      // );
      // if (
      //   isLoanSubmittionReadyForBulk &&
      //   isLoanSubmittionReadyForBulk.length > 0
      // ) {
      //   isLoanSubmittionReadyForBulk = JSON.parse(isLoanSubmittionReadyForBulk);
      //   if (Array.isArray(isLoanSubmittionReadyForBulk)) {
      //     setDropDownLoanOptions(isLoanSubmittionReadyForBulk);
      //     const isDisableResp = isLoanSubmittionReadyForBulk.filter(
      //       (it: any) => {
      //         return !it.isReadyForSubmit;
      //       }
      //     );
      //     const disableSubmit = isDisableResp.length > 0;
      //     sessionStorage.removeItem("isLoanSubmittionReadyForBulk");
      //     return disableSubmit;
      //   }
      // } else {

      let dropDownLoanOptionsNew: any[] = JSON.parse(
        JSON.stringify(dropDownLoanOptions)
      );
      const isDisableResp = dropDownLoanOptions.filter(
        (it: any, idx: number) => {
          const isReadyForSubmit = checkSubmitDisabled(
            it.selectedProgramDetails,
            it.waiverDetails,
            it.filteredPartners
          );
          if (isReadyForSubmit) {
            dropDownLoanOptionsNew[idx].isReadyForSubmit = false;
          } else {
            dropDownLoanOptionsNew[idx].isReadyForSubmit = true;
          }
          return !isReadyForSubmit;
        }
      );
      setDropDownLoanOptions(dropDownLoanOptionsNew);
      // return isDisableResp.length === dropDownLoanOptions.length ? false : true;
      return isDisableResp.length !== dropDownLoanOptions.length;
      // }
    } else {
      return checkSubmitDisabled(
        selectedProgramDetails,
        waiversCreated,
        globalFilteredPartners
      );
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    selectedLoan?.value,
    // eslint-disable-next-line
    selectedLoan?.selectedProgramDetails,
    // eslint-disable-next-line
    selectedLoan?.waiverDetails,
    globalFilteredPartners
  ]);

  const handleBack = () => {
    if (!loanIdForWaiver) return;
    dispatch(getWaiverList(loanIdForWaiver, loanStage, true));
    setShowWaiverPage(false);
  };

  const getSubHeader = () => {
    return showWaiverPage ? null : (
      <div style={{ display: "flex" }} data-testid="takeout-subheader-modal">
        <InfoOutlinedIcon
          htmlColor="#ffffff"
          style={{ color: "darkslategrey" }}
        />
        <div style={{ paddingBottom: 10, fontFamily: "inherit", fontSize: 12 }}>
          <b>
            {dropDownLoanOptions?.length > 1
              ? `${dropDownLoanOptions?.length} Loans don't `
              : "Loan doesn't "}
            qualify for any of the {`${partnertype}s`}. Please edit the data in
            the{" "}
            <Link
              type="a"
              underline="always"
              href="?selectedTab=loanDetails"
              onClick={() => {
                setPopUpOpen(false);
              }}
            >
              Loan
            </Link>{" "}
            to make it eligible.
          </b>
        </div>
      </div>
    );
  };

  const getHeader = () => {
    return showWaiverPage ? (
      <Button
        variant="contained"
        data-testid="takeout-header-modal"
        color="primary"
        startIcon={<KeyboardArrowLeftIcon />}
        onClick={() => {
          handleBack();
        }}
      >
        <span>{`Back to ${partnertype} Selection`}</span>
      </Button>
    ) : (
      `${partnertype} Selection`
    );
  };
  const handleSubmit = () => {
    if (excelData) {
      const payload: {
        loanId: any;
        loanDetails: {
          loanInfo: { takeoutPartner: any; takeoutPartnerStatus: string };
        };
      }[] = [];
      dropDownLoanOptions.forEach((it: any) =>
        payload.push({
          loanId: it.value,
          loanDetails: {
            loanInfo: {
              takeoutPartner: it.selectedProgramDetails?.key,
              takeoutPartnerStatus: "PENDING"
            }
          }
        })
      );
      updateTakeOutPartnerData(payload).then(() => {
        submitLoanWithTakeOutPartner();
      });
    } else {
      submitLoanWithTakeOutPartner(selectedProgramDetails?.key);
    }
  };
  return (
    <>
      <ProgrammeSelectionModalWrapper
        isOpen={popUpOpen}
        header={getHeader()}
        subheader={getSubHeader()}
        handleOptional={() => {
          // sessionStorage.setItem(
          //   "isLoanSubmittionReadyForBulk",
          //   JSON.stringify(dropDownLoanOptions)
          // );
          setPopUpOpen(false);
        }}
        isPrimaryBtnDisabled={isSubmitDisabled}
        body={
          <PricingAndGuidlinesSectionModalBody
            showWaiverPage={showWaiverPage}
            setSelectedLoan={setSelectedLoan}
            loansWithoutAssignedPartner={loansWithoutAssignedPartner}
            setLoanDataForWaiver={setLoanDataForWaiver}
            onRequestWaiverClick={handleRequestWaiverClick}
            rateSheetTypeData={rateSheetTypeData}
            waiverDetails={waiverDetails}
            setGlobalSelected={setSelectedProgramDetails}
            setGlobalFilteredPartners={setGlobalFilteredPartners}
            selectedProgramDetails={selectedProgramDetails}
            dropDownLoanOptions={dropDownLoanOptions}
            setDropDownLoanOptions={setDropDownLoanOptions}
            handleBack={handleBack}
            selectedLoan={selectedLoan}
            criticalTests={criticalTests}
          />
        }
        primaryBtnName={
          loanStage === LoanStage.fes ? "Evaluate Guideline" : "Submit"
        }
        maxWidth={"xl"}
        handleClose={handleSubmit}
        hideButtons={!showWaiverPage}
      />
    </>
  );
}
