import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { BudgetEntryHeaderWrapper } from "./ModalHeader/BudgetEntryHeaderWrapper";
import { BudgetDocsHeaderWrapper } from "./DocsViewPanel/BudgetDocsViewWrapper";
import { FieldsPanelWrapper } from "./FieldsPanel/FieldsPanelWrapper";
import { useParams } from "react-router-dom";
import {
  formatResponse,
  getDocuments,
  getLoanById,
  getPropertyOriginalValuesByLoanId
  // GET_LOAN_BY_ID_FAILED,
  // GET_LOAN_BY_ID_SUCCESS,
  // GET_PROPERTY_DETAILS,
  // GET_PROPERTY_FAILED
} from "../create-loan/create-loan.action";
import { useDispatch, useSelector } from "react-redux";
// import docFile from "../images/docFile.svg"
import { RootState } from "../stores/rootReducer";
import { URLParamsType } from "../utils/constants";
import { getBudgetHistory } from "../stores/budgetReview/budgetReview.action";
import {
  formatLoanDetailsResponse,
  formatPropertyResponse
} from "../create-loan/CreateLoan";
import {
  updateLoanDetailsInRedux,
  updatePropertyDetailsInRedux
} from "../stores/loanAndPropertyDetails/loanAndPropertyDetails.action";
import {
  hideLoader,
  showLoader
} from "../stores/loaderAndException/loaderAndException.action";
import { useBudgetStyles } from "./BudgetReview.style";
// import { useStyles } from "../create-loan/documents/DocumentsForm.style";
import { taskNames } from "../stores/tasks/Tasks.action";
import { push } from "connected-react-router";
import { setBudgetDocsList } from "../stores/budgetReview/budgetReview.reducer";
import {
  getPropertyDetails,
  setLoanByIdFailed,
  setLoanByIdSuccess
} from "../create-loan/create-loan.reducer";

export const InternalBudgetReview = () => {
  const [budgetDocs, setbudgetDocs] = useState<any[]>([]);
  const [showNoDocs, setShowNoDocs] = useState<boolean>(false);
  const budgetClasses = useBudgetStyles();
  const navigate = useNavigate();
  // const dpcsClasses = useStyles();
  const dispatch = useDispatch();
  const { loanId, loanStage, loanType } = useParams<URLParamsType>() as any;

  useEffect(() => {
    if (loanType && loanStage && loanId && loanType !== "BridgeLoan") {
      navigate(`/internal/loan/createLoan/${loanType}/${loanStage}/${loanId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanType, loanStage, loanId]);

  const getDataByLoanID = async () => {
    try {
      const response = await getLoanById(loanId, loanStage);
      const loanDetails = formatResponse(response.data);
  
      updateLoanDetailsInRedux(loanDetails, dispatch); // credit-eval reducer
  
      const formattedLoanData = formatLoanDetailsResponse(
        response.data,
        loanStage,
        null
      );
  
      dispatch(
        setLoanByIdSuccess({
          loan: formattedLoanData,
          keepTab: true,
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(setLoanByIdFailed());
    }
  };
  

  const getPropDataByLoanID = async () => {
    getPropertyOriginalValuesByLoanId(loanId, loanStage)
      .then((resp: any) => {
        updatePropertyDetailsInRedux(resp.data, dispatch);
        const formattedPropertyData = formatPropertyResponse(
          resp.data,
          loanStage,
          loanType
        );
        dispatch(
          getPropertyDetails({
            propertyDetails: formattedPropertyData,
            thirtyYearLoanPropertyDetails: formattedPropertyData
          })
        );
      })
      .catch((e) => {
        console.error(e);
        // dispatch({
        //   type: GET_PROPERTY_FAILED,
        //   error: {
        //     message: e.message
        //   }
        // });
      });
  };

  useEffect(() => {
    if (!loanId) return;
    let reqBody = {
      resourceIds: [loanId],
      resourceTypes: ["LOAN"],
      taskNames: [
        taskNames.TAG,
        taskNames.SPLIT,
        taskNames.REVIEW,
        taskNames.RECONCILE,
        taskNames.BUDGET_REVIEW,
        taskNames.BUDGET_APPROVE
      ]
    };
    dispatch(getBudgetHistory(reqBody, loanId));
  }, [loanId, dispatch]);

  useEffect(() => {
    if (!(loanId && loanStage)) return;
    getDataByLoanID();
    getPropDataByLoanID();
    // eslint-disable-next-line
  }, [loanId, loanStage]);

  const { loanDetails } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );

  const { loanState } = loanDetails;

  useEffect(() => {
    // loanState && getDocsList();
    if (!loanState || loanState === "Data Entry") return;

    (async () => {
      dispatch(showLoader());
      try {
        const [prePurchaseList, postCloseList] = (await Promise.all([
          getDocuments(
            loanId,
            `${loanStage}-prePurchase`,
            `${loanStage}-prePurchase`,
            loanState,
            true
          ) as any,
          getDocuments(
            loanId,
            `${loanStage}-postClose`,
            `${loanStage}-postClose`,
            loanState
          )
        ])) as any;

        const budgetDocs = filterObjectsByTag([
          ...postCloseList.response.data,
          ...prePurchaseList.response.data
        ]);
        if (budgetDocs.length) {
          const tempBudgetFilesList = budgetDocs.map(
            (fileData: { name: string }) => {
              const fileType = fileData.name.split(".").pop()?.toLowerCase();
              return {
                ...fileData,
                fileType,
                ...{
                  title: fileData.name
                }
              };
            }
          );
          setbudgetDocs(tempBudgetFilesList);
          if (tempBudgetFilesList.length) {
            setShowNoDocs(true);
          }

          dispatch(setBudgetDocsList(tempBudgetFilesList));
        }
        if (!budgetDocs.length) {
          setShowNoDocs(true);
        }
        dispatch(hideLoader());
      } catch (e) {
        console.error(e);
        dispatch(hideLoader());
      }
    })();
    // eslint-disable-next-line
  }, [loanId, loanState, loanStage]);

  function filterObjectsByTag(docsList: any[]) {
    const filteredObjects: any[] = [];
    docsList.forEach((doc) => {
      if (doc.isPurchasePrimary) {
        const tags = doc.TagsInternal || [];
        for (let j = 0; j < tags.length; j++) {
          const tagCode = tags[j].tag.code;
          if (tagCode === "REBD" || tagCode === "SCOW") {
            filteredObjects.push(doc);
            break;
          }
        }
      }
    });
    return filteredObjects;
  }

  return (
    <Grid
      container
      direction="column"
      className={budgetClasses.budgetReviewContainer}
    >
      <Grid item style={{ height: "10%" }}>
        <BudgetEntryHeaderWrapper />
      </Grid>
      {budgetDocs.length ? (
        <Grid
          item
          container
          direction="row"
          style={{ height: "90%", paddingTop: 10 }}
        >
          <Grid item xs={12} md={5}>
            {/* left side component with 40% width */}
            <BudgetDocsHeaderWrapper budgetDocs={budgetDocs} />
          </Grid>
          <Grid item xs={12} md={7}>
            {/* right side component */}
            <FieldsPanelWrapper />
          </Grid>
        </Grid>
      ) : null}
      {!showNoDocs && (
        //   <div className={`${dpcsClasses.noDocs} , ${dpcsClasses.docsUpload}`}>
        //     {/* <img src={docFile} alt="No file" /> */}
        //     <br />
        //     <br />
        //     <div style={{ fontSize: 16 }}>
        //       {"Documents are unavailable to proceed"}
        //     </div>
        //   </div>
        // ) : (
        <Grid>
          <Grid
            xs={12}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: "100vh" }}
          >
            <Grid item>
              <CircularProgress size={35} style={{ marginLeft: 15 }} />
              <Typography>Loading...!</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
