/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import { Grid, IconButton, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  BrowseFileButton,
  ConfirmFormModal,
  LoanStage,
  ViewModeTextField,
  WarningModal
} from "@toorak/tc-common-fe-sdk";
import { v1 as uuidv1 } from "uuid";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CustomTable from "../../masterView/CustomTable";
import {
  hideLoader,
  showLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { BasicDropDown } from "../../ui-components/BasicDropDown";
import { FileBar } from "../waiver-form/FileBar";
import { waiverFormStyles } from "../waiver-form/Common.style";
import { InfoIcon } from "../../icons/info";
import {
  getExcelDataInSession,
  isLATUser
} from "../../utils/AccessManagement";
import {
  postRateLock
} from "../create-loan.action";
import { setLoanByIdSuccess } from "../create-loan.reducer";
import { formatFesLoanDetailsForRedux } from "../../stores/FrontEndSizer/fesLoanCreation.action";
import { formatValueByType, MergeRecursive } from "../../utils/formatChecks";
import { showPopUpMessages } from "../../stores/loanAndPropertyDetails/loanAndPropertyDetails.reducer";
import { FESRateLockMessageHeader, FESRateLockPoupBody, MessageBody, MessageHeader } from "./InfoPopUp";
import { ObjectType } from "../../masterView/common";
import { labelFieldName30year } from "../guideline-outputs/ToorakProceedsFields";
import { GuidelinesAndPricingComparision } from "../guidelines-and-pricing-comparision";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRowGrid: {
      "& .MuiTableCell-root.MuiTableCell-head": {
        padding: "16px"
      },
      "& .MuiTableCell-root": {
        padding: "16px 16px",
        border: "0px"
      }
    },
    headerRowBackground: {
      background: "white",
      borderBottom: "1px solid #f2f2f2"
    }
  })
);

const cols = (
  lockinValuesMap: ObjectType,
  handleIndividualChange: Function
) => {
  const assignedPartnerTitle = isLATUser() ? "Partner Name" : "Program Name";
  return [
    { id: "originatorLoanId", title: "Originator Loan Id" },
    { id: "toorakLoanId", title: "Toorak Loan Id" },
    { id: "assignedPartner", title: assignedPartnerTitle },
    {
      id: "rateLockPeriod",
      title: "Lock-in Period",
      width: 240,
      renderCustom: (data: any) => {
        const options = lockinValuesMap?.[data?.takeOutPartner];
        return (
          <BasicDropDown
            selected={data?.rateLockPeriod}
            handleChange={(e: any) =>
              handleIndividualChange(e, data.toorakLoanId)
            }
            menuOptions={options}
            selectStyles={{ fontSize: "12px" }}
            placeHolderText="Select Lock-in Period"
            width="220px"
          />
        );
      }
    },
    {
      id: "actualInterestRate",
      title: "Interest Rate",
      renderCustom: (data: ObjectType) => {
        return formatValueByType(data.actualInterestRate, "percentage");
      }
    },
    { id: "basePrice", title: "Base Price" },
    { id: "totalPriceAdjustment", title: "Price Adj.-Total" },
    { id: "pppMaxPrice", title: "Prepayment Penalty-Max Price" },
    { id: "maxAllowedToorakPrice", title: "Max Allowed Toorak Price" },
    {
      id: "statepppRestrictionMaxPrice",
      title: "State PPP Restriction - Max Price"
    },
    {
      id: "finalToorakPrice",
      title: "Final Toorak Price",
      renderCustom: (data: ObjectType) => {
        const charge = parseFloat(
          lockinValuesMap?.[data?.assignedPartner]?.find(
            (it: ObjectType) => it.value === data?.rateLockPeriod
          )?.charge ?? "0"
        );
        return (charge + parseFloat(data.finalToorakPrice)).toFixed(4);
      }
    }
  ];
};

const LockInselectionForMultiLoan = (props: any) => {
  const {
    lockIn,
    handleLockInSelection,
    loansArray,
    updatedOptions,
    // updateIsAllSelected,
    lockinValuesMap,
    selectedRowsData,
    setSelectedRowsData,
    showDisabledOptionsMessage,
    rateSheetType,
    currentSelectedPartner,
    loanId,
    loansWithoutAssignedPartner,
    takeOutPartnerList,
    rateLockOptions,
    setCurrentSelectedPartner,
    eligibleTakeOutPartnerResult,
    requestLockApproval,
    noLocks
  } = props;
  const classes = useStyles();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [openWarning, setOpenWarning] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState<string>(
    loansArray[0].toorakLoanId
  );
  const [isLockPopUp, setIsLockPopUp] = useState<boolean>(false);

  const loansDropdownArray = useMemo(() => {
    return loansArray.map((it: ObjectType) => ({
      ...it,
      value: it.toorakLoanId,
      label: `${it.originatorLoanId}/${it.toorakLoanId}`
    }));
  }, [loansArray]);

  const loansMap: Map<string, ObjectType> = useMemo(() => {
    return new Map(loansArray.map((it: ObjectType) => [it.toorakLoanId, it]));
  }, [loansArray]);

  function haveSameAssignedPartner(arr: any[]) {
    if (arr.length === 0) {
      return true; // An empty array is considered to have the same assignedPartner.
    }

    const firstAssignedPartner = arr[0].assignedPartner;

    const diffAssignedPartner = arr.find(
      obj => obj.assignedPartner !== firstAssignedPartner
    );

    return diffAssignedPartner === undefined;
  }

  const onRowSelect = (e: any, ids: string[], rows: any[]) => {
    let loanArr: string[] = [];
    let loanArrData: any[] = [];
    if (e.target.checked) {
      loanArr = [...selectedRows, ...ids];
      loanArrData = [...selectedRowsData, ...rows];
    } else {
      // loanArr =
      selectedRowsData.forEach((item: any) => {
        if (!ids.includes(item.toorakLoanId)) {
          loanArr.push(item.toorakLoanId);
          loanArrData.push(item);
        }
      });
    }
    const isSameAssignedPartner = e.target.checked
      ? haveSameAssignedPartner(loanArrData)
      : true;
    if (isSameAssignedPartner) {
      setSelectedRows(loanArr);
      setSelectedRowsData(loanArrData);
      // updateIsAllSelected(!(loansArray.length === loanArr.length));
    } else {
      setOpenWarning(true);
    }
  };

  const onSelectAll = (checked: boolean) => {
    if (checked) {
      const isSameAssignedPartner = haveSameAssignedPartner(loansArray);
      if (isSameAssignedPartner) {
        const rowIdsArr = loansArray.map((it: any) => it.toorakLoanId);
        setSelectedRows(rowIdsArr);
        setSelectedRowsData(loansArray);
        // updateIsAllSelected(false);
      } else {
        setOpenWarning(true);
      }
    } else {
      setSelectedRows([]);
      setSelectedRowsData([]);

      // updateIsAllSelected(true);
    }
  };
  const allSelected = useMemo(() => {
    return selectedRows.length === loansArray.length;
  }, [selectedRows, loansArray]);

  const handleIndividualChange = (e: any, id: string) => {
    handleLockInSelection({ [id]: e.target.value });
  };

  const columns = cols(lockinValuesMap, handleIndividualChange);

  const handleChange = (e: any) => {
    let temp: { [key: string]: string } = {};
    selectedRows.forEach((id: string) => {
      temp = { ...temp, [id]: e.target.value };
    });
    handleLockInSelection(temp);
  };

  const getWarningBody = () => {
    const tp = isLATUser() ? "partner" : "program";
    return `Please select loans assigned to same ${tp}.`;
  };

  const handleLoanIdChange = (e: any) => {
    const selected: any = loansMap.get(e?.target?.value);
    setSelectedLoan(selected?.toorakLoanId);
  };
  const fields = labelFieldName30year("");

  useEffect(() => {
    if (noLocks) {
      setIsLockPopUp(true);
    } else {
      setIsLockPopUp(false);
    }
  }, [noLocks]);

  return (
    <div>
      {openWarning && (
        <WarningModal
          isOpen={openWarning}
          handleClose={() => {
            setOpenWarning(false);
          }}
          header="Warning"
          body={getWarningBody()}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "1rem",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#eef6ff",
          border: "1px solid #f2f2f2"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: 600,
            fontSize: "16px"
          }}
        >
          <InfoIcon />
          <span style={{ marginLeft: "5px", fontSize: "14px" }}>
            Change the lock-in period from dropdown
          </span>
        </div>
        <BasicDropDown
          selected={lockIn}
          handleChange={handleChange}
          menuOptions={updatedOptions}
          selectStyles={{ fontSize: "12px" }}
          placeHolderText="Select Lock-in Period"
          width="250px"
        />
      </div>
      {showDisabledOptionsMessage && (
        <div
          style={{
            padding: 4,
            backgroundColor: "#e9fafd",
            borderRadius: 4,
            fontSize: 12,
            marginBottom: "1rem"
          }}
        >
          <IconButton
            aria-label="information about rate lock"
            style={{ padding: 0 }}
          >
            <InfoIcon />
          </IconButton>
          Extended lock terms not eligible due to min Toorak Price.
        </div>
      )}
      <div>
        <CustomTable
          rowsData={loansArray}
          columnsData={columns}
          classes={classes}
          onRowSelect={onRowSelect}
          selectAllRows={onSelectAll}
          allSelected={allSelected}
          selectedLoans={selectedRows}
          showCheckBox
        />
      </div>
      <div>
        <div
          style={{
            display: "flex",
            padding: "1rem",
            background: "#eef6ff",
            border: "1px solid #f2f2f2"
          }}
        >
          <div
            style={{
              marginRight: "2rem",
              marginLeft: "16px",
              fontWeight: 600,
              fontSize: "14px",
              alignItems: "center",
              display: "flex"
            }}
          >
            {!isLockPopUp ? "Pricing Adjustments" : "Select Eligible Program"}
          </div>
          <BasicDropDown
            selected={selectedLoan}
            handleChange={handleLoanIdChange}
            menuOptions={loansDropdownArray}
            selectStyles={{ fontSize: "12px" }}
            dropDownLabel={"Loan ID:"}
            labelAlignmnet="row"
          />
        </div>
        <div>
          <Grid
            container
            spacing={4}
            style={{
              padding: "24px"
            }}
          >
            {isLockPopUp ? (
              <GuidelinesAndPricingComparision
                loanId={
                  loansWithoutAssignedPartner?.length ? selectedLoan : loanId
                }
                programSelectConfig={{
                  isProgramSelectView: false,
                  isLockPopUp,
                  // onRequestWaiverClick: onRequestWaiverClick,
                  currentSelectedPartner: currentSelectedPartner,
                  takeOutPartnerLists: takeOutPartnerList,
                  rateLockOptions: rateLockOptions,
                  setCurrentSelectedPartner: setCurrentSelectedPartner,
                  eligibleTakeOutPartnerResult: eligibleTakeOutPartnerResult
                }}
              />
            ) : (
              <>
                {fields.map((field: ObjectType) => {
                  const val = loansMap.get(selectedLoan)?.[field?.field];
                  return (
                    <Grid container item xs={12} sm={3} md={3} xl={3} key={field.label}>
                      <ViewModeTextField
                        value={formatValueByType(val, field.fieldType)}
                        label={field.label}
                      />
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>
        </div>
      </div>
      {requestLockApproval && <div style={{ display: "flex", margin: "1rem 2rem", background: "rgb(238, 246, 255)", padding: "1rem" }}>
        <span style={{paddingRight: "8px"}}><InfoIcon /></span>
        <span>
        The Toorak purchase price of the loan is determined by the most up-to-date rate sheet posted to Toorak Connect at the time the loan is locked. The lock date will be the date the loan tape or loan data and minimum documentation files are initially uploaded to Toorak Connect (this includes pre-close loan submissions). If the minimum documentation files are not uploaded to Toorak Connect at the time the loan is locked, the loan may be placed on “hold” however, lock period will continue to be engaged.  Toorak must close/buy the loan within the lock period. The minimum document checklist can be found in our document repository.
        </span>
      </div>}
    </div>
  );
};

const LockInselectionForSingleLoanFES = (props: any) => {
  const {
    lockIn,
    handleLockInSelection,
    loanId,
    filesList,
    setFilesList,
    // updateIsAllSelected,
    options,
    showDisabledOptionsMessage,
    rateSheetType,
    currentSelectedPartner,
    loansWithoutAssignedPartner,
    takeOutPartnerList,
    rateLockOptions,
    loansArray,
    setCurrentSelectedPartner,
    eligibleTakeOutPartnerResult
  } = props;

  const classes = waiverFormStyles();
  const [isLockPopUp, setIsLockPopUp] = useState<boolean>(false);
  const [selectedLoan, setSelectedLoan] = useState<string>(
    loansArray[0].toorakLoanId
  );

  const loansDropdownArray = useMemo(() => {
    return loansArray.map((it: ObjectType) => ({
      ...it,
      value: it.toorakLoanId,
      label: `${it.originatorLoanId}/${it.toorakLoanId}`
    }));
  }, [loansArray]);

  const loansMap: Map<string, ObjectType> = useMemo(() => {
    return new Map(loansArray.map((it: ObjectType) => [it.toorakLoanId, it]));
  }, [loansArray]);

  useEffect(() => {
    if (eligibleTakeOutPartnerResult?.eligiblePartners?.length >= 1) {
      setIsLockPopUp(true);
    } else {
      setIsLockPopUp(false);
    }
  }, [loanId, eligibleTakeOutPartnerResult]);

  const handleLockinChange = (e: any) => {
    const val = e.target.value;
    handleLockInSelection({ [loanId]: val });
  };

  const deleteBtnHandler = (index: number) => {
    const temp = [...filesList];
    temp.splice(index, 1);
    setFilesList([...temp]);
    // updateIsAllSelected(true);
  };

  const handleLoanIdChange = (e: any) => {
    const selected: any = loansMap.get(e?.target?.value);
    setSelectedLoan(selected?.toorakLoanId);
  };

  /**
   *
   * @param event file details; expecting only 1 file to be uploaded
   * @param props
   */
  const handleFileUpload = (event: any) => {
    const maxFileSize = 20971520;
    // Docs number limit check

    const files = event;
    const newFileList: any[] = [];

    const file = files[0];

    // files size check. max 5 MB allowed
    // remove the excedding file
    if (file.size > maxFileSize) {
      alert(`Max File upload size allowed is ${maxFileSize / 1048576}MB`);

      return;
    }

    file.uploading = true;
    file.uploaded = false;
    file.percentCompleted = 100;
    file.tag = [];
    file.tagCodeList = [];

    file.docsId = uuidv1();
    file.uniqueId = uuidv1();

    newFileList.push(file);

    setFilesList(newFileList);
    // updateIsAllSelected(false);
    // return newFileList;
  };

  return (
    <Grid container>
      {isLockPopUp ? (
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              padding: "1rem",
              background: "#eef6ff",
              border: "1px solid #f2f2f2"
            }}
          >
            <div
              style={{
                marginRight: "2rem",
                marginLeft: "16px",
                fontWeight: 600,
                fontSize: "14px",
                alignItems: "center",
                display: "flex"
              }}
            >
              {"Select Eligible Program"}
            </div>
            <BasicDropDown
              selected={selectedLoan}
              handleChange={handleLoanIdChange}
              menuOptions={loansDropdownArray}
              selectStyles={{ fontSize: "12px" }}
              dropDownLabel={"Loan ID:"}
              labelAlignmnet="row"
            />
          </div>
          <div>
            <Grid
              container
              spacing={4}
              style={{
                margin: 0,
                width: "100%"
              }}
            >
              {isLockPopUp ? (
                <GuidelinesAndPricingComparision
                  loanId={
                    loansWithoutAssignedPartner?.length ? selectedLoan : loanId
                  }
                  programSelectConfig={{
                    isProgramSelectView: false,
                    isLockPopUp,
                    // onRequestWaiverClick: onRequestWaiverClick,
                    currentSelectedPartner: currentSelectedPartner,
                    takeOutPartnerLists: takeOutPartnerList,
                    rateLockOptions: rateLockOptions,
                    setCurrentSelectedPartner: setCurrentSelectedPartner,
                    eligibleTakeOutPartnerResult: eligibleTakeOutPartnerResult
                  }}
                />
              ) : null}
            </Grid>
          </div>
        </div>
      ) : (
        <Grid container style={{ padding: "0 20px" }}>
          <Grid item style={{ width: "80%" }}>
            <div
              style={{
                padding: 4,
                backgroundColor: "#e9fafd",
                borderRadius: 4,
                boxShadow: "0 2px 12px 0 rgb(50 50 93 / 21%)",
                fontSize: 12
              }}
            >
              <IconButton
                aria-label="information about rate lock"
                style={{ padding: "0 1rem 0 0" }}
              >
                <InfoIcon />
              </IconButton>
              <span>
                Rate Lock can be added on the loans which have term sheet.
              </span>
            </div>
            {showDisabledOptionsMessage && (
              <div
                style={{
                  padding: 4,
                  backgroundColor: "#e9fafd",
                  borderRadius: 4,
                  boxShadow: "0 2px 12px 0 rgb(50 50 93 / 21%)",
                  fontSize: 12,
                  marginTop: "1rem"
                }}
              >
                <IconButton
                  aria-label="information about rate lock"
                  style={{ padding: "0 1rem 0 0" }}
                >
                  <InfoIcon />
                </IconButton>

                <span>
                  Extended lock terms not eligible due to min Toorak Price.
                </span>
              </div>
            )}
          </Grid>
          <Grid item style={{ width: "80%", paddingTop: "20px" }}>
            <BasicDropDown
              selected={lockIn}
              handleChange={handleLockinChange}
              menuOptions={options}
              selectStyles={{ fontSize: "12px" }}
              dropDownLabel="Lock-in Period"
              width="100%"
            />
          </Grid>
          <Grid item style={{ paddingTop: "20px", width: "100%" }}>
            {/* File list display */}
            {filesList.length ? (
              <Grid item xs={12}>
                {filesList.map((value: any, i: number) => (
                  <div key={value.uniqueId} className={classes.fileBarWrapper}>
                    <React.Suspense fallback={null}>
                      <FileBar
                        value={value}
                        deleteHandler={() => deleteBtnHandler(i)}
                        isOnlyIcon
                      />
                    </React.Suspense>
                  </div>
                ))}
              </Grid>
            ) : (
              <BrowseFileButton
                testId="upload-term-sheet-fes"
                text="Upload Term Sheet"
                startIcon={<span />}
                onChange={(e: any) => handleFileUpload(e.target.files)}
                dontAllowMultipleSelect
                disabled={false}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export const LockInSelectionPopup = (props: any) => {
  const {
    showPopup,
    hidePopup,
    loansArray,
    onSubmit,
    loanId,
    loanStage,
    updateRateLock,
    overriddenValues,
    runFesEval,
    lockinValues = {},
    loanDetails,
    isPostSubmit,
    rateSheetType,
    eligibleTakeOutPartnerResult,
    setCurrentSelectedPartner,
    currentSelectedPartner,
    loansWithoutAssignedPartner,
    takeOutPartnerList,
    rateLockOptions,
    takeoutPartnerId,
    loansResult,
    noLocks
  } = props;

  const { dailyCreditLimit } = eligibleTakeOutPartnerResult ?? {};

  const isWithinDailyLimit =
    dailyCreditLimit &&
    !dailyCreditLimit[currentSelectedPartner?.partnerId]?.isWithinLimit;

  const [lockInSelections, setLockInSelections] = useState<{
    [key: string]: string;
  }>({});
  const [filesList, setFilesList] = React.useState<any[]>([]);
  const dispatch = useDispatch();
  const excelData = getExcelDataInSession();
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);

  const showDisabledOptionsMessage = useMemo(() => {
    return lockinValues?.[loanId]?.lockValues?.some((it: any) => it?.disabled);
  }, [lockinValues, loanId]);

  const defaultLockInPeriod = useMemo(() => {
    return lockinValues?.[loanId]?.lockValues?.[0]?.value ?? "";
  }, [lockinValues, loanId, excelData]);

  const handleLockInSelection = useCallback(
    (config: { [key: string]: string }) => {
      setLockInSelections({ ...lockInSelections, ...config });
    },
    [lockInSelections]
  );

  const shouldRender = useMemo(() => {
    if (eligibleTakeOutPartnerResult?.eligiblePartners?.length === 1 && loanStage === LoanStage.fes) {
      runFesEval(true);
      return false;
    }
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eligibleTakeOutPartnerResult, loanStage]);

  const updatedLoans = useMemo(() => {
    return (
      loansArray?.map((it: any) => ({
        ...it,
        rateLockPeriod:
          lockInSelections?.[it?.toorakLoanId] ?? defaultLockInPeriod,
        assignedPartner: currentSelectedPartner?.assignedPartner,
        takeOutPartner: currentSelectedPartner?.partnerId
      })) ?? []
    );
  }, [
    loansArray,
    lockInSelections,
    defaultLockInPeriod,
    currentSelectedPartner
  ]);

  const updatedOptions: any[] = useMemo(() => {
    if (!selectedRowsData.length) return [];
    return (
      lockinValues?.[selectedRowsData[0].toorakLoanId]?.lockValues?.map(
        (it: ObjectType) => ({
          ...it,
          style: { width: "250px" }
        })
      ) ?? []
    );
  }, [lockinValues, selectedRowsData]);

  const lockinValuesMap: any = useMemo(() => {
    if (!loansArray?.length) return;
    const valuesMap: ObjectType = {};
    const uniqueAssignedPartners: { [key: string]: any } = {};
    const uniqueAssignedPartnersArray = loansArray.reduce(
      (acc: any[], obj: { assignedPartner: any }) => {
        const { assignedPartner } = obj;

        if (!uniqueAssignedPartners[assignedPartner]) {
          uniqueAssignedPartners[assignedPartner] = true;
          acc.push(obj);
        }
        return acc;
      },
      []
    );
    uniqueAssignedPartnersArray.forEach((loan: any) => {
      const partnerId =
        lockinValues?.[loan.toorakLoanId].partnerId ||
        lockinValues?.[loan.toorakLoanId].assignedPartner;
      valuesMap[partnerId] = lockinValues?.[loan.toorakLoanId]?.lockValues;
    });
    return valuesMap;
    // eslint-disable-next-line
  }, [lockinValues, selectedRowsData]);

  const requestLockApproval = useMemo(() => {
    return (loansResult?.[loanId]?.[loanStage]?.[currentSelectedPartner.partnerId]?.loanResult?.[loanId]?.loanCharacterisations?.rateLockApprovalRequired === "Yes");
  }, [loansResult, loanId, loanStage, currentSelectedPartner]);

  const handleSuccess = async () => {
    dispatch(showLoader());
    const newLoanId = Object.keys(lockinValues)[0];
    if ((lockinValues?.[newLoanId]?.lockValues).length === 0) {
      hidePopup();
      dispatch(hideLoader());
    } else {
      const payload = updatedLoans.map((data: any) => {
        const returnPayload: any = {
          loanId: data.toorakLoanId,
          loanDetails: {
            loanConfig: {
              rateLockPeriod: data?.rateLockPeriod ?? null,
              rateLockApprovalRequired: requestLockApproval ? "PENDING" : "APPROVED"
            }
          }
        };

        if (currentSelectedPartner?.partnerId !== takeoutPartnerId) {
          returnPayload.loanDetails.loanInfo = {
            takeoutPartner: currentSelectedPartner?.partnerId,
            takeoutPartnerStatus: "OVERRIDDEN"
          };
        }
        return returnPayload;
      });
      onSubmit(payload);
    }
  };

  const RunRuleForRateLock = async (loan: any, newLoanDetails: any) => {
    const newDs: any = {};
    const lopin =
      overriddenValues?.loanResult || overriddenValues?.propertiesResults
        ? MergeRecursive(overriddenValues, newDs)
        : JSON.parse(JSON.stringify(newDs));
    runFesEval(lopin, null, newLoanDetails);
  };

  const handleSuccessFES = async (overRidedata: any, filesList: any[]) => {
    try {
      dispatch(showLoader());
      // uncomment when fes rate locks are enabled
      // if (filesList.length > 0) {
      //   // go ahead
      //   // 1. upload the docs
      //   // 2. call to save the rate lock
      //   const secondaryIden = "TERMSHEET-FES";

      //   const body = getFileUpladBody(
      //     filesList[0]?.name,
      //     loanId,
      //     "LOAN",
      //     secondaryIden,
      //     secondaryIden
      //   );
      //   const preAssignedURL: any = await getPreAssignedURL(
      //     body,
      //     filesList[0]?.type
      //   );
      //   if (preAssignedURL.url && !preAssignedURL.Error) {
      //     const res: any = await uploadAWSFile(filesList[0], {}, preAssignedURL);
      //     if (res.status === 200) {
      //       const insertBody = [
      //         {
      //           name: filesList[0]?.name,
      //           path: preAssignedURL.url,
      //           box_document_path: "<box_document_path>",
      //           createdBy: getCookie("org_id"),
      //           updatedBy: getCookie("org_id"),
      //           tags: []
      //         }
      //       ];
      //       const primaryIdentifier = "LOAN";
      //       const insertDocsResp: any = await insertDocs(
      //         insertBody,
      //         loanId,
      //         secondaryIden,
      //         secondaryIden,
      //         primaryIdentifier
      //       );
      //       if (insertDocsResp.status !== 200) {
      //         return;
      //       }
      //     }
      //   }
      // }
      const payload = updatedLoans.map((data: any) => {
        return {
          loanId: data.toorakLoanId,
          rateLockType: "SIZER_RATE_LOCK",
          sizerRateLockPeriod: Number(data.rateLockPeriod)
          // rateLockPeriod: Number(data.rateLockPeriod)
        };
      });

      const response = await postRateLock(payload);
      const formattedLoanData: any = formatFesLoanDetailsForRedux(response[0]);
      dispatch(
        setLoanByIdSuccess({
          loan: formattedLoanData
        })
      );
      if (
        loanStage === LoanStage.fes &&
        currentSelectedPartner?.partnerId &&
        currentSelectedPartner?.partnerId !== takeoutPartnerId
      ) {
        formattedLoanData.loanDetails.loanInfo = {
          takeoutPartner: currentSelectedPartner?.partnerId,
          takeoutPartnerStatus: "OVERRIDDEN"
        };
      }
      // run rules here
      await RunRuleForRateLock(updatedLoans[0], {
        ...loanDetails,
        ...formattedLoanData.loanDetails
      });
      dispatch(hideLoader());
      hidePopup();
      if (updateRateLock) {
        updateRateLock();
        dispatch(
          showPopUpMessages({
            message: FESRateLockPoupBody(),
            header: FESRateLockMessageHeader()
          })
        );
      } else {
        dispatch(
          showPopUpMessages({
            message: MessageBody(lockinValues),
            header: MessageHeader()
          })
        );
      }
      // showing the TC msg, LPP-9097
   
    } catch (e) {
      console.error(e);
      dispatch(hideLoader());
      hidePopup();
    }
  };
  const isDisable = () => {
    return (
      isWithinDailyLimit ||
      (loansArray.length === 1 &&
        Object.keys(lockinValues?.[loanId]?.lockValues ?? {})?.length === 0
        ? false
        : loansArray.length !==
        updatedLoans.filter((it: any) => it.rateLockPeriod).length)
    );
  };
  const popupBody = () => {
    let body;
    const newLoanId = Object.keys(lockinValues ?? {})[0];
    if (
      lockinValues?.[newLoanId]?.lockValues?.length === 0 &&
      loanStage !== LoanStage.fes
    ) {
      body = (
        <div style={{ margin: "2rem" }}>
          Currently the Rate Locking is not available. If you want to rate lock
          you can try again in an hour.
        </div>
      );
    } else if (loanStage !== LoanStage.fes) {
      body = <LockInselectionForMultiLoan
        lockIn={
          lockInSelections?.[selectedRowsData[0]?.toorakLoanId] ??
          defaultLockInPeriod}
        handleLockInSelection={handleLockInSelection}
        loansArray={updatedLoans}
        updatedOptions={updatedOptions}
        selectedRowsData={selectedRowsData}
        setSelectedRowsData={setSelectedRowsData}
        lockinValuesMap={lockinValuesMap}
        showDisabledOptionsMessage={showDisabledOptionsMessage}
        rateSheetType={rateSheetType}
        eligibleTakeOutPartnerResult={eligibleTakeOutPartnerResult}
        lockinValues={lockinValues}
        setCurrentSelectedPartner={setCurrentSelectedPartner}
        currentSelectedPartner={currentSelectedPartner}
        loanId={loanId}
        loansWithoutAssignedPartner={loansWithoutAssignedPartner}
        takeOutPartnerList={takeOutPartnerList}
        rateLockOptions={rateLockOptions}
        requestLockApproval={requestLockApproval}
        noLocks={noLocks}
      // updateIsAllSelected
      />;
    } else {
      body = <LockInselectionForSingleLoanFES
        lockIn={
          lockInSelections?.[updatedLoans[0].toorakLoanId] ??
          defaultLockInPeriod}
        handleLockInSelection={handleLockInSelection}
        loanId={ updatedLoans[0].toorakLoanId}
        options={ lockinValues?.[updatedLoans[0].toorakLoanId]?.lockValues}
        loanStage={loanStage}
        filesList={filesList}
        setFilesList={setFilesList}
        showDisabledOptionsMessage={ showDisabledOptionsMessage}
        rateSheetType={rateSheetType}
        eligibleTakeOutPartnerResult={eligibleTakeOutPartnerResult}
        lockinValues={lockinValues}
        setCurrentSelectedPartner={setCurrentSelectedPartner}
        currentSelectedPartner={currentSelectedPartner}
        loansWithoutAssignedPartner={loansWithoutAssignedPartner}
        takeOutPartnerList={takeOutPartnerList}
        rateLockOptions={rateLockOptions}
        loansArray={ updatedLoans}
        // updateIsAllSelected
      />;
    }
    return body;
  };

  const submitButtonText = useMemo(() => {
    if (isPostSubmit && !requestLockApproval) {
      return "Apply";
    }
    if (isPostSubmit && requestLockApproval) {
      return "Request Lock";
    }
    if (lockinValues?.[loanId]?.lockValues?.length === 0) {
      return "Ok";
    }
    if (loanStage === LoanStage.fes && takeOutPartnerList.length > 1) {
      return "Submit";
    }
    if (requestLockApproval) {
      return "Request Lock";
    }
    return "Lock & Submit";
  }, [isPostSubmit, lockinValues, loanId, loanStage, takeOutPartnerList, loansResult]);

  return (
    <>
      {shouldRender &&
        (
          <>
            <ConfirmFormModal
              isOpen={showPopup}
              handleClose={() => {
                hidePopup();
                setCurrentSelectedPartner({
                  assignedPartner: "",
                  partnerId: ""
                });
              }}
              primaryBtnName={submitButtonText}
              header={
                loanStage === LoanStage.fes && takeOutPartnerList.length > 1
                  ? "Submit Loan"
                  : loanStage === LoanStage.fes || isPostSubmit
                    ? "Apply Rate Lock"
                    : "Submit Loan"
              }
              body={popupBody()}
              confirmCondition={isDisable()}
              successHandler={
                loanStage === LoanStage.fes
                  ? () => handleSuccessFES("", filesList)
                  : handleSuccess
              }
              containerStyle={{ minWidth: "1000px" }}
              size={"800px"}
              footerStyles={{ paddingTop: "0px", marginTop: "25px" }}
              hideSecondaryBtn={lockinValues?.[loanId]?.lockValues?.length === 0}
            />
          </>
        )
      }
    </>
  );
};